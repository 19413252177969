import { __decorate } from "tslib";
import { Component, Vue, Prop, Emit, } from 'vue-property-decorator';
import { CDatePicker, CStaffSelector, CTimePicker, CSelect, CInputNumber, CInput, } from './index';
let ConditionItem = class ConditionItem extends Vue {
    onChangeConditionField(code) {
        return code;
    }
    onSelectConditionField(condition) {
        return condition;
    }
    onChangeConditionOperator(value) {
        return value;
    }
    delCondition(condition) {
        return condition;
    }
};
__decorate([
    Prop()
], ConditionItem.prototype, "condition", void 0);
__decorate([
    Prop()
], ConditionItem.prototype, "fields", void 0);
__decorate([
    Emit('onChangeConditionField')
], ConditionItem.prototype, "onChangeConditionField", null);
__decorate([
    Emit('onSelectConditionField')
], ConditionItem.prototype, "onSelectConditionField", null);
__decorate([
    Emit('onChangeConditionOperator')
], ConditionItem.prototype, "onChangeConditionOperator", null);
__decorate([
    Emit('delCondition')
], ConditionItem.prototype, "delCondition", null);
ConditionItem = __decorate([
    Component({
        name: 'ConditionItem',
        components: {
            CDatePicker,
            CStaffSelector,
            CTimePicker,
            CSelect,
            CInputNumber,
            CInput,
        },
    })
], ConditionItem);
export default ConditionItem;
