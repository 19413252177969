import { __decorate } from "tslib";
import { Component, Vue, Prop, Watch, } from 'vue-property-decorator';
import { ComponentTable, EmptyPage } from '../../../common-comp';
let PageAuth = class PageAuth extends Vue {
    constructor() {
        super(...arguments);
        this.activeKey = [];
        this.isInit = true;
        this.isOperatePageCheckbox = false;
    }
    get pages() {
        return [this.page];
    }
    onPageCodeChange() {
        this.init();
    }
    onPagesChange(pages) {
        if (this.isOperatePageCheckbox) {
            this.isOperatePageCheckbox = false;
            return;
        }
        if (!Array.isArray(pages))
            return;
        for (const page of pages) {
            const comps = page.components;
            if (!Array.isArray(comps))
                return;
            for (const comp of comps) {
                const authCheckboxs = comp.componentAuthCheckbox;
                for (const authCheckboxKey in authCheckboxs) {
                    const checkbox = authCheckboxs[authCheckboxKey];
                    if (checkbox.checked) {
                        page.isCheckedPage = true;
                    }
                }
            }
        }
    }
    created() {
        this.init();
    }
    onChangeCollapse(key) {
        this.isInit = false;
        this.activeKey = key;
    }
    init() {
        this.isInit = true;
        this.activeKey = [this.pageCode];
    }
    changePageCheckbox() {
        this.isOperatePageCheckbox = true;
        if (!Array.isArray(this.pages))
            return;
        for (const page of this.pages) {
            const comps = page.components;
            if (page.isCheckedPage)
                return;
            if (!Array.isArray(comps))
                return;
            for (const comp of comps) {
                const authCheckboxs = comp.componentAuthCheckbox;
                for (const authCheckboxKey in authCheckboxs) {
                    const checkbox = authCheckboxs[authCheckboxKey];
                    checkbox.checked = false;
                }
            }
        }
    }
};
__decorate([
    Prop({ default: () => { } })
], PageAuth.prototype, "page", void 0);
__decorate([
    Prop({ default: '' })
], PageAuth.prototype, "pageCode", void 0);
__decorate([
    Watch('pageCode')
], PageAuth.prototype, "onPageCodeChange", null);
__decorate([
    Watch('pages', { deep: true })
], PageAuth.prototype, "onPagesChange", null);
PageAuth = __decorate([
    Component({
        name: 'PageAuth',
        components: { ComponentTable, EmptyPage },
    })
], PageAuth);
export default PageAuth;
