import { __decorate } from "tslib";
import { Component, Vue, Prop, Emit, } from 'vue-property-decorator';
import { Icon } from '@h3/antd-vue';
import { NodeType, SourceType, } from '@/typings/organization/org-manage';
import Avatar from './avatar.vue';
let StaffReselectedContainer = class StaffReselectedContainer extends Vue {
    constructor() {
        super(...arguments);
        this.NodeType = NodeType;
        this.SourceType = SourceType;
    }
    removeNode(index) {
        return index;
    }
    dblclick() {
        return true;
    }
};
__decorate([
    Prop()
], StaffReselectedContainer.prototype, "selectedNodes", void 0);
__decorate([
    Emit('del')
], StaffReselectedContainer.prototype, "removeNode", null);
__decorate([
    Emit('changeDblClick')
], StaffReselectedContainer.prototype, "dblclick", null);
StaffReselectedContainer = __decorate([
    Component({
        name: 'StaffReselectedContainer',
        components: {
            AIcon: Icon,
            Avatar,
        },
    })
], StaffReselectedContainer);
export default StaffReselectedContainer;
