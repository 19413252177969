import { TreeNodeType } from '@/typings/app-auth';
import AppGroupData from './app-group-data';
const treeData = [
    {
        title: '菜单权限',
        key: '1',
        code: '1',
        nodeType: TreeNodeType.MenuAuth,
        children: [],
        isError: false,
        scopedSlots: { title: 'title' },
    },
    {
        title: '分组权限',
        key: '2',
        code: '2',
        nodeType: TreeNodeType.GroupAuth,
        children: [],
        isError: false,
        scopedSlots: { title: 'title' },
    },
];
export default class AppData extends AppGroupData {
    constructor() {
        super(...arguments);
        this.treeData = JSON.parse(JSON.stringify(treeData));
        // 应用元数据
        this.appMetaData = {};
    }
    get copyAppMetaData() {
        let copyAppMetaData = {};
        try {
            copyAppMetaData = JSON.parse(JSON.stringify(this.appMetaData));
        }
        catch (e) {
            copyAppMetaData = {};
        }
        return copyAppMetaData;
    }
    get appOriginalMenus() {
        return this.copyAppMetaData.appMenus || [];
    }
    get appOriginalGroup() {
        return this.copyAppMetaData.groupVOS || [];
    }
    onDataChange() {
        this.treeData = JSON.parse(JSON.stringify(treeData));
        const appOriginalMenus = this.appOriginalMenus;
        const appOriginalGroup = this.appOriginalGroup;
        if (Array.isArray(appOriginalMenus) && appOriginalMenus.length) {
            const menuTreeData = super.generateMenuTreeData(appOriginalMenus) || [];
            this.treeData[0].menuTree = menuTreeData;
        }
        if (Array.isArray(appOriginalGroup) && appOriginalGroup.length) {
            const groupTreeData = super.generateGroupTreeData(appOriginalGroup) || [];
            this.treeData[1].children = groupTreeData;
        }
    }
}
