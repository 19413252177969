import { __decorate } from "tslib";
import { Vue, Prop } from 'vue-property-decorator';
import * as Utils from '@/common/utils';
export default class AuthTableBase extends Vue {
    constructor() {
        super(...arguments);
        this.isCheckAllVisible = false;
        this.isCheckAllWritable = false;
    }
    get checkbox() {
        return `${this.tableType}AuthCheckbox`;
    }
    // 是否全选可见
    get visibleCheckAll() {
        let result = false;
        if (this.compData.length) {
            result = this.compData.every((item) => item[this.checkbox].isVisible.checked);
        }
        return result;
    }
    // 是否全选可写
    get writableCheckAll() {
        let result = false;
        if (this.compData.length) {
            result = this.compData.every((item) => item[this.checkbox].isWritable.checked);
        }
        return result;
    }
    // 是否半选可见
    get indeterminateVisible() {
        let res = false;
        if (this.visibleCheckAll) {
            res = false;
        }
        else {
            res = this.compData.some((item) => item[this.checkbox].isVisible.checked);
        }
        return res;
    }
    // 是否半选可写
    get indeterminateWritable() {
        let res = false;
        if (this.writableCheckAll) {
            res = false;
        }
        else {
            res = this.compData.some((item) => item[this.checkbox].isWritable.checked);
        }
        return res;
    }
    /**
     * 禁止编辑可见复选框
     * @param record
     * @returns
     */
    disabledVisible(record) {
        let disabledEditVisible = false;
        if (!record.isRequiredRule && typeof record.isEditTableVsibleOrWrite === 'boolean') {
            disabledEditVisible = !record.isEditTableVsibleOrWrite && this.tableType === 'field';
        }
        return disabledEditVisible;
    }
    /**
     * 禁止编辑可写复选框
     * @param record
     * @returns
     */
    disabledWritable(record) {
        const disabledEditTableWritable = !record.editTableWritable && this.tableType === 'field';
        let disabledEditTableVsibleOrWrite = false;
        if (!record.isRequiredRule && typeof record.isEditTableVsibleOrWrite === 'boolean') {
            disabledEditTableVsibleOrWrite = !record.isEditTableVsibleOrWrite && this.tableType === 'field';
        }
        return disabledEditTableWritable || disabledEditTableVsibleOrWrite;
    }
    async checkWritable(item) {
        // if (this.tableType === 'component') return;
        await Utils.sleep(50);
        const authCheckbox = item[this.checkbox];
        if (authCheckbox.isWritable.checked) {
            authCheckbox.isVisible.checked = true;
        }
    }
    async checkVisible(item) {
        // if (this.tableType === 'component') return;
        await Utils.sleep(50);
        const authCheckbox = item[this.checkbox];
        if (!authCheckbox.isVisible.checked) {
            authCheckbox.isWritable.checked = false;
        }
    }
    onChangeVisible() {
        const isCheckedAll = this.visibleCheckAll;
        this.isCheckAllVisible = isCheckedAll || this.isCheckAllVisible;
        this.isCheckAllVisible = !this.isCheckAllVisible;
        if (!this.isCheckAllVisible) {
            this.isCheckAllWritable = false;
        }
        this.compData.forEach((item) => {
            const disabledVisible = this.disabledVisible(item);
            if (disabledVisible)
                return;
            item[this.checkbox].isVisible.checked = this.isCheckAllVisible;
            if (!this.isCheckAllVisible) {
                item[this.checkbox].isWritable.checked = false;
            }
        });
    }
    onChangeWritable() {
        const isCheckedAll = this.writableCheckAll;
        this.isCheckAllWritable = isCheckedAll || this.isCheckAllWritable;
        this.isCheckAllWritable = !this.isCheckAllWritable;
        if (this.isCheckAllWritable) {
            this.isCheckAllVisible = true;
        }
        this.compData.forEach((item) => {
            const disabledEditWritable = this.disabledWritable(item);
            if (disabledEditWritable)
                return;
            if (this.isCheckAllWritable) {
                item[this.checkbox].isVisible.checked = true;
            }
            item[this.checkbox].isWritable.checked = this.isCheckAllWritable;
        });
    }
}
__decorate([
    Prop({ default: () => [] })
], AuthTableBase.prototype, "compData", void 0);
__decorate([
    Prop({ default: 'component' })
], AuthTableBase.prototype, "tableType", void 0);
