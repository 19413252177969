import { __decorate } from "tslib";
import { Vue, Prop, Watch } from 'vue-property-decorator';
import DataItem from './data-item';
import * as Utils from '@/common/utils';
export default class DataAuth extends Vue {
    constructor() {
        super(...arguments);
        this.curField = {};
        this.curFieldCode = '';
        this.curFieldRowIndex = 0;
        this.fields = {
            systemField: {
                groupName: '系统数据项',
                fieldList: [],
            },
            businessField: {
                groupName: '业务数据项',
                fieldList: [],
            },
        };
    }
    get fieldList() {
        const fieldList = [
            ...this.fields.systemField.fieldList,
            ...this.fields.businessField.fieldList,
        ];
        return fieldList;
    }
    get errorMessMap() {
        const mess = this.fieldList.map((item) => {
            return {
                code: item.code,
                message: `${item.name}不能为空`,
            };
        });
        const messMap = {};
        mess.forEach((item) => {
            messMap[item.code] = item.message;
        });
        return messMap;
    }
    onConditionFieldChange(conditionField = {}) {
        this.fields.systemField.fieldList = conditionField.systemField || [];
        this.fields.businessField.fieldList = conditionField.businessField || [];
    }
    /**
     * 重置条件
     */
    resetDataAuth() {
        this.conditionDataAuth.condition = 'and';
        this.conditionDataAuth.selectedConditionList = [];
    }
    /**
     * 单击条件行
     */
    onSelectField(field) {
        this.curFieldCode = field.code;
        this.curFieldRowIndex = field.rowIndex;
    }
    /**
     * 字段改变发生的变化
     */
    onChangeField(code) {
        const fieldList = this.fieldList;
        const field = fieldList.find((item) => item.code === code);
        const curField = fieldList.find((item) => item.code === this.curFieldCode);
        curField.isAdded = false;
        field.isAdded = true;
        const conditionData = this.conditionData(field);
        const conditionField = this.conditionDataAuth.selectedConditionList.find((item) => {
            return item.rowIndex === this.curFieldRowIndex;
        });
        Object.assign(conditionField, conditionData);
    }
    /**
     * 连接符改变发生的变化
     */
    onChangeOperator(operatorValue) {
        const isNullOrNotNull = DataItem.isNullOrNotNull(operatorValue);
        const conditionField = this.conditionDataAuth.selectedConditionList.find((item) => {
            return item.rowIndex === this.curFieldRowIndex;
        });
        const fieldList = this.fieldList;
        const curField = fieldList.find((item) => item.code === this.curFieldCode);
        // 空与非空都要隐藏组件
        if (isNullOrNotNull) {
            const defaultValue = DataItem.getDefaultValue(curField);
            conditionField.componetType = {};
            conditionField.conditionValue = defaultValue;
        }
        else {
            const componentType = this.getComponentType(curField);
            this.$set(conditionField, 'componetType', componentType);
            this.$set(conditionField, 'staffSelectorOptions', DataItem.getStaffSelectorOpts(curField, operatorValue));
            this.$set(conditionField, 'conditionValue', DataItem.getDefaultValue(curField));
        }
    }
    /**
     * 添加条件
     */
    addCondition() {
        const fields = this.fieldList;
        const item = fields.find((field) => !field.isAdded);
        if (!item)
            return;
        item.isAdded = true;
        const isAddAll = fields.every((field) => field.isAdded);
        if (isAddAll) {
            fields.forEach((field) => {
                field.isAdded = false;
            });
        }
        const conditionData = this.conditionData(item);
        const data = {
            rowIndex: this.conditionDataAuth.selectedConditionList.length,
            code: item.code,
            ...conditionData,
        };
        this.conditionDataAuth.selectedConditionList.push(data);
    }
    /**
     * 一行条件的数据
     */
    conditionData(item = {}) {
        const operator = DataItem.getOperator(item);
        if (!Array.isArray(operator))
            return {};
        if (!operator.length)
            return {};
        const selectData = DataItem.getSelectData(item);
        const defaultValue = DataItem.getDefaultValue(item);
        const componetType = this.getComponentType(item);
        const defaultOperator = operator[0].value;
        let precision = 0;
        let int = 0;
        // 整数位数和小数位数
        if (DataItem.isDecimal(item) || DataItem.isInt(item)) {
            const format = item.format ? item.format.split('.') : [];
            int = format[0].length;
            if (DataItem.isDecimal(item)) {
                precision = format[1].length;
            }
        }
        const errorInfo = {
            isError: false,
            message: this.errorMessMap[item.code],
        };
        const result = {
            name: item.name,
            code: item.code,
            format: item.format,
            uiControl: item.uiControl,
            valueType: item.valueType,
            propertyLength: item.propertyLength,
            isSystemProperty: item.isSystemProperty,
            precision: precision,
            int: int,
            conditionOperatorValue: defaultOperator,
            conditionValue: defaultValue,
            componetType: componetType,
            staffSelectorOptions: DataItem.getStaffSelectorOpts(item, defaultOperator),
            operatorData: operator,
            selectData: selectData,
            errorInfo: errorInfo,
        };
        return result;
    }
    /**
     * 条件行的组件类型
     */
    getComponentType(item) {
        return {
            isInput: DataItem.isInput(item),
            isDate: DataItem.isDate(item),
            isDateTime: DataItem.isDateTime(item),
            isTime: DataItem.isTime(item),
            isInt: DataItem.isInt(item),
            isDecimal: DataItem.isDecimal(item),
            isSelect: DataItem.isSelect(item),
            isStaffSelector: DataItem.isStaffSelector(item),
        };
    }
    /**
     * 删除条件行
     */
    delCondition(condition) {
        const selectedConditionList = this.conditionDataAuth
            .selectedConditionList;
        const fieldList = this.fieldList;
        const conditionField = fieldList.find((field) => field.code === condition.code);
        if (conditionField) {
            conditionField.isAdded = false;
        }
        const index = selectedConditionList.findIndex((item) => {
            return item.rowIndex === condition.rowIndex;
        });
        if (index !== -1) {
            selectedConditionList.splice(index, 1);
            selectedConditionList.forEach((item, idx) => {
                item.rowIndex = idx;
            });
        }
        ;
    }
    /**
     * 清空已选条件
     */
    async clearDataAuth() {
        await Utils.sleep(300);
        this.conditionDataAuth.condition = 0;
        this.conditionDataAuth.selectedConditionList = [];
    }
    /**
     * 校验非空
     */
    validateField(selectedConditionList = []) {
        const errMessage = [];
        for (const item of selectedConditionList) {
            const defaultValue = DataItem.getDefaultValue(item);
            const orgField = this.fieldList.find((field) => field.code === item.code);
            const isNullOrNotNull = DataItem.isNullOrNotNull(item.conditionOperatorValue);
            if (isNullOrNotNull)
                continue;
            if (Array.isArray(defaultValue) && !item.conditionValue.length) {
                errMessage.push(`条件“${orgField.selectName}”不能为空`);
            }
            else if (typeof defaultValue === 'string' && !item.conditionValue) {
                errMessage.push(`条件“${orgField.selectName}”不能为空`);
            }
            else if (typeof defaultValue === 'undefined' &&
                item.conditionValue === undefined) {
                errMessage.push(`条件“${orgField.selectName}”不能为空`);
            }
        }
        this.showMessage(errMessage);
        return !errMessage.length;
    }
    /**
     * 显示错误弹窗
     * @param errMessage
     */
    async showMessage(errMessage = [], messageType = 'error') {
        await Utils.sleep(400);
        if (errMessage.length) {
            const h = this.$createElement;
            this.$message[messageType](h('div', {
                style: {
                    display: 'inline-block',
                    textAlign: 'left',
                    verticalAlign: 'top',
                },
            }, [
                errMessage.map((err) => h('div', { style: { lineHeight: '22px' } }, err)),
            ]));
        }
    }
    /**
     * 整合待提交的数据
     */
    confirmAuthData() {
        const isValid = this.validateField(this.conditionDataAuth.selectedConditionList);
        if (!isValid)
            return false;
        return true;
    }
}
__decorate([
    Prop({ default: () => { } })
], DataAuth.prototype, "conditionField", void 0);
__decorate([
    Prop({ default: () => { } })
], DataAuth.prototype, "conditionDataAuth", void 0);
__decorate([
    Prop()
], DataAuth.prototype, "modelCode", void 0);
__decorate([
    Watch('conditionField', { immediate: true })
], DataAuth.prototype, "onConditionFieldChange", null);
