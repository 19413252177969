import { __decorate } from "tslib";
import { Component } from 'vue-property-decorator';
import AuthTableBase from './auth-table-base';
let ComponentTable = class ComponentTable extends AuthTableBase {
    constructor() {
        super(...arguments);
        this.compAuthColumns = [
            {
                title: this.tableType === 'component' ? '组件权限' : '字段权限',
                dataIndex: 'name',
                key: 'name',
                width: 200,
                ellipsis: true,
                scopedSlots: { customRender: 'name' },
            },
            {
                dataIndex: 'isVisible',
                key: 'isVisible',
                slots: { title: 'visibleTitle' },
                align: 'right',
                scopedSlots: { customRender: 'isVisible' },
            },
            {
                dataIndex: 'isWritable',
                key: 'isWritable',
                slots: { title: 'writableTitle' },
                align: 'right',
                scopedSlots: { customRender: 'isWritable' },
            },
        ];
    }
    disabledVisible(record) {
        return super.disabledVisible(record);
    }
    disabledWritable(record) {
        return super.disabledWritable(record);
    }
    onComponentChangeVisible() {
        super.onChangeVisible();
    }
    onComponentChangeWritable() {
        super.onChangeWritable();
    }
    componentCheckVisible(item) {
        super.checkVisible(item);
    }
    componentCheckWritable(item) {
        super.checkWritable(item);
    }
};
ComponentTable = __decorate([
    Component({
        name: 'ComponentTable',
    })
], ComponentTable);
export default ComponentTable;
