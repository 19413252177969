import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
let ProcessAuth = class ProcessAuth extends Vue {
    constructor() {
        super(...arguments);
        this.checkAll = false;
        this.columns = [
            {
                title: '流程名称',
                dataIndex: 'name',
                key: 'name',
                width: 400,
            },
            {
                dataIndex: 'auth',
                key: 'auth',
                align: 'right',
                slots: { title: 'authTitle' },
                scopedSlots: { customRender: 'auth' },
            },
        ];
        this.data = [
            {
                name: '流程名称名称',
                key: 1,
                isChecked: false,
            },
            {
                name: '流程名称名称',
                key: 2,
                isChecked: false,
            },
            {
                name: '流程名称名称',
                key: 3,
                isChecked: false,
            },
            {
                name: '流程名称名称',
                key: 4,
                isChecked: false,
            },
            {
                name: '流程名称名称',
                key: 5,
                isChecked: false,
            },
        ];
    }
};
ProcessAuth = __decorate([
    Component({
        name: 'ProcessAuth',
    })
], ProcessAuth);
export default ProcessAuth;
