import { Vue } from 'vue-property-decorator';
import AppMenuData from './app-menu-data';
import { TreeNodeType } from '@/typings/app-auth';
import { UnShowSystemCodeItemType, UnShowBusinessDataItemType, UnShowAdvanceBusinessDataItemType, SystemCodeItemType, } from '@/components/app-manage/auth-config-set/common-comp/data-auth/data-item-condition-type';
const moduleData = [
    {
        title: '领域模型',
        key: '1/domainModel',
        nodeType: TreeNodeType.Model,
    },
    {
        title: '页面权限',
        key: '2/pathAuth',
        nodeType: TreeNodeType.Page,
    },
];
export default class AppGroupData extends AppMenuData {
    /**
     * 生成分组树数据
     */
    generateGroupTreeData(groups) {
        const generateGroupTreeData = (tree, nodeType) => {
            const result = [];
            if (!Array.isArray(tree) || !tree.length)
                return result;
            tree.forEach((item) => {
                let moduleTree = [];
                if (nodeType === TreeNodeType.Group) {
                    const dataModel = { domainModels: item.domainModels };
                    const uiModel = {
                        pageGroups: item.pageGroups,
                        pages: item.pages,
                    };
                    const flowModel = item.flowModel;
                    moduleTree = this.getModuleChildren(item.groupCode, dataModel, uiModel, flowModel);
                }
                const node = {
                    title: item.groupName,
                    key: item.groupCode,
                    code: item.groupCode,
                    nodeType: nodeType,
                    moduleTree: moduleTree,
                    isError: false,
                    scopedSlots: { title: 'title' },
                };
                result.push(node);
            });
            return result;
        };
        const groupTreeData = generateGroupTreeData(groups, TreeNodeType.Group);
        return groupTreeData;
    }
    /**
     * 返回分组下的领域模型和页面权限模块
     */
    getModuleChildren(groupCode = 'nogroup', dataModel = {}, uiModel = {}, flowModel = {}) {
        const result = [];
        const domainModels = dataModel.domainModels || [];
        const uiModels = uiModel;
        const flowModels = flowModel.pages || [];
        moduleData.forEach((item) => {
            const data = {
                title: item.title,
                groupCode: groupCode,
                nodeType: item.nodeType,
                key: `${item.key}/${groupCode}`,
                isChckedAll: false,
                indeterminate: false,
                isError: false,
                scopedSlots: {
                    title: 'title',
                },
            };
            if (item.nodeType === TreeNodeType.Model) {
                data.children = this.getDomainModelChildren(domainModels);
            }
            if (item.nodeType === TreeNodeType.Page) {
                data.children = this.getPageChildren(uiModels);
            }
            if (item.nodeType === TreeNodeType.Process)
                data.flowModels = flowModels;
            result.push(data);
        });
        return result;
    }
    /**
     * 生成页面
     */
    getPageChildren(uiModels = {}) {
        const pages = uiModels.pages || [];
        const groupPages = this.generateGroupPage(uiModels.pageGroups) || [];
        const result = this.generatePage([...pages, ...groupPages]);
        return result;
    }
    /**
     * 生成页面分组的页面
     */
    generateGroupPage(pageGroups = []) {
        const groupPages = [];
        if (!Array.isArray(pageGroups) || !pageGroups.length)
            return groupPages;
        pageGroups.forEach((groupItem) => {
            const pages = groupItem.pages || [];
            groupPages.push(...pages);
        });
        return groupPages;
    }
    /**
     * 生成默认分组的页面
     */
    generatePage(pages) {
        if (!Array.isArray(pages) || !pages.length)
            return [];
        const resultPages = [];
        pages.forEach((page) => {
            try {
                if (!page.data || page.data === 'null')
                    page.data = '{}';
                const data = JSON.parse(page.data) || {};
                const components = this.filterPermissionComponent(data.designer);
                // 是否是公开页面 公开页面不展示
                const isPublicPage = this.getIsPublicPage(data.designer); //data.designer[data.activeId]?.props?.publicPage;
                if (!isPublicPage) {
                    const resultPage = {
                        components: components,
                        isCheckedPage: false,
                        code: page.code,
                        key: page.code,
                        title: page.name,
                        nodeType: TreeNodeType.PageData,
                    };
                    resultPages.push(resultPage);
                }
            }
            catch (e) {
                Vue.prototype.$errorcatcher.showMessagePopup({
                    title: '错误提示！',
                    message: [e, 'uiModel数据报错'],
                    correctGuide: '请联系开发人员检查',
                });
            }
        });
        return resultPages;
    }
    /**
     * 获取有权限的组件
     */
    filterPermissionComponent(components = {}) {
        const comps = [];
        const componentName = Object.keys(components);
        if (!Array.isArray(componentName) || !componentName.length)
            return comps;
        componentName.forEach((compName) => {
            const comp = components[compName];
            if (comp.isRegisterPermission) {
                comp.code = comp.id;
                const componentAuthCheckbox = this.getAuthCheckbox();
                comps.push({
                    code: comp.id,
                    name: comp.name,
                    componentAuthCheckbox,
                });
            }
        });
        return comps;
    }
    /**
     * 生成领域模型
     */
    getDomainModelChildren(domainModels = []) {
        const result = [];
        domainModels.forEach((item) => {
            const requiredRule = item.bizRule?.mandatoryRules || [];
            const bizSchemas = this.getBizSchemas(item.bizSchemas, requiredRule);
            const domainModel = {
                code: item.code,
                bizSchemas: bizSchemas,
                key: item.code,
                title: item.name,
                isError: false,
                nodeType: TreeNodeType.ModelData,
                scopedSlots: { title: 'title' },
            };
            result.push(domainModel);
        });
        return result;
    }
    /**
     * 生成领域模型的数据
     * @param bizSchemas
     */
    getBizSchemas(bizSchemas = [], requiredRule = []) {
        const result = [];
        bizSchemas.forEach((item) => {
            // 条件展示数据（系统数据项和业务数据项）
            const conditionField = this.generateFields(item.properties, 'condition');
            // 高级权限条件展示数据（系统数据项和业务数据项）
            const advanceConditionField = this.generateFields(item.properties, 'condition', undefined, undefined, UnShowAdvanceBusinessDataItemType);
            // 高级权限结果展示数据（系统数据项和业务数据项）
            const ruleField = this.generateFields(item.properties, 'rule', requiredRule);
            // 需要提交到服务器的数据
            const serviceData = this.addServiceDataToBizSchemas();
            const properties = this.getProperties(item.properties, requiredRule);
            const schemas = {
                code: item.code,
                name: item.name,
                isError: false,
                properties: properties,
                conditionField,
                advanceConditionField,
                ruleField,
                serviceData,
            };
            result.push(schemas);
        });
        return result;
    }
    /**
     * 把需要提交到后端的数据，比如：
     * 数据权限、操作权限、字段权限、高级权限的添加到schema上
     */
    addServiceDataToBizSchemas() {
        const serviceData = {
            // 自定义数据权限条件
            customAuthData: {
                condition: 'and',
                selectedConditionList: [],
            },
            // 高级权限
            advanceAuthData: [],
            // 数据权限
            dataAuth: 0,
            // 操作权限
            operateAuth: [],
        };
        return serviceData;
    }
    /**
     * 生成模型字段
     * @param properties
     */
    getProperties(properties = [], requiredRule = []) {
        const result = [];
        properties.forEach((item) => {
            const editable = item.extensions && item.extensions.editable;
            const isRequiredRule = this.isRequiredRule(item, requiredRule);
            const fieldAuthCheckbox = this.getAuthCheckbox(item);
            const property = {
                format: item.format,
                code: item.code,
                name: item.name,
                isSystemProperty: item.isSystemProperty,
                uiControl: item.uiControl,
                valueType: item.valueType,
                fieldAuthCheckbox,
                editTableWritable: editable,
                isRequiredRule: isRequiredRule,
            };
            if (!property.isSystemProperty) {
                property.isEditTableVsibleOrWrite = !item.required;
            }
            result.push(property);
        });
        return result;
    }
    /**
     * 生成选中的状态
     * 字段权限和页面权限需要用到
     */
    getAuthCheckbox(item = {}) {
        const authCheckbox = {
            isVisible: {
                checked: false,
                value: 1,
            },
            isWritable: {
                checked: false,
                value: 2,
            },
        };
        if (item.required && !item.isSystemProperty) {
            authCheckbox.isVisible.checked = true;
            authCheckbox.isWritable.checked = true;
        }
        return authCheckbox;
    }
    /**
     * 判断字段是否有带条件的必填规则
     * @param requiredRule
     * @returns
     */
    isRequiredRule(item = {}, requiredRule = []) {
        let required = false;
        if (requiredRule.length) {
            required = requiredRule.every((rule) => {
                const mandatoryProperties = rule?.mandatoryProperties || [];
                if (mandatoryProperties.length) {
                    return mandatoryProperties.every((prop) => {
                        return prop.propertyCode === item.code;
                    });
                }
                else {
                    return false;
                }
            });
        }
        return required;
    }
    /**
     * 生成系统数据项和业务数据项
     */
    generateFields(list = [], type = 'condition', requiredRule = [], unShowSystemCodeItemType = UnShowSystemCodeItemType, unShowBusinessDataItem = UnShowBusinessDataItemType) {
        const systemField = [];
        const businessField = [];
        const unShowBusinessDataItemTypeValue = Object.values(unShowBusinessDataItem);
        const unSystemDataItemTypeValue = Object.values(unShowSystemCodeItemType);
        let properties = [];
        try {
            properties = JSON.parse(JSON.stringify(list));
        }
        catch (e) {
            properties = [];
        }
        if (Array.isArray(properties)) {
            for (const item of properties) {
                const editable = item.extensions && item.extensions.editable;
                const fieldAuthCheckbox = this.getAuthCheckbox(item);
                const condition = {
                    format: item.format,
                    code: item.code,
                    name: item.name,
                    isSystemProperty: item.isSystemProperty,
                    uiControl: item.uiControl,
                    valueType: item.valueType,
                    propertyLength: item.propertyLength,
                    selectName: `${item.name}[${item.code}]`,
                    fieldAuthCheckbox,
                    editTableWritable: editable,
                };
                if (!condition.isSystemProperty) {
                    condition.isEditTableVsibleOrWrite = !item.required;
                }
                if (type === 'rule') {
                    condition.isRequiredRule = this.isRequiredRule(item, requiredRule);
                }
                const selectData = item.code === SystemCodeItemType.Status
                    ? item.extensions?.docStatusType
                    : item.extensions?.valueRange;
                condition.selectData = selectData || [];
                if (type === 'condition') {
                    // 已添加的条件的标识
                    condition.isAdded = false;
                }
                else {
                    // 已添加的结果的标识
                    condition.isAddedResult = false;
                }
                if (condition.isSystemProperty) {
                    if (type === 'condition') {
                        // 条件获取指定的数据项
                        if (!unSystemDataItemTypeValue.includes(condition.code)) {
                            systemField.push(condition);
                        }
                    }
                    else {
                        // 结果获取所有数据项
                        systemField.push(condition);
                    }
                }
                else {
                    if (type === 'condition') {
                        // 条件获取指定的数据项
                        if (!unShowBusinessDataItemTypeValue.includes(condition.valueType)) {
                            businessField.push(condition);
                        }
                    }
                    else {
                        // 结果获取所有数据项
                        businessField.push(condition);
                    }
                }
            }
        }
        return {
            systemField,
            businessField,
        };
    }
    /**
     * 判断页面是否是公开页面
     */
    getIsPublicPage(components = {}) {
        let componentName = Object.keys(components);
        let isPublicPage = false;
        for (let i = 0; i < componentName.length; i++) {
            if (components[componentName[i]].type === 'Page') {
                isPublicPage = !!(components[componentName[i]]?.props?.publicPage);
                break;
            }
        }
        return isPublicPage;
    }
}
