import { __decorate } from "tslib";
import { Component } from 'vue-property-decorator';
import ComponentBase from './component-base';
let CInput = class CInput extends ComponentBase {
};
CInput = __decorate([
    Component({
        name: 'CInput',
    })
], CInput);
export default CInput;
