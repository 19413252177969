import { __decorate } from "tslib";
import { Component } from 'vue-property-decorator';
import ComponentBase from './component-base';
let CDatePicker = class CDatePicker extends ComponentBase {
};
CDatePicker = __decorate([
    Component({
        name: 'CDatePicker',
    })
], CDatePicker);
export default CDatePicker;
