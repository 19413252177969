import { __decorate } from "tslib";
import { Component, Watch, Mixins } from 'vue-property-decorator';
import ComponentBase from '../component-base';
import CStaffSelectorBase from './c-staff-selector';
import { StaffSelector } from '@cloudpivot-hermes/common-components';
import { StaffSelectorValue, ValueType } from '../../data-auth/data-item-value-type';
import { EqualOrNotEqual, ContainOrNotContain, OfOrNotOf, } from '../../data-auth/data-item-operator-type';
import { UnitType } from '@/typings/organization/org-manage';
import StaffSelectorInput from './staff-selector-input.vue';
let CStaffSelector = class CStaffSelector extends Mixins(ComponentBase, CStaffSelectorBase) {
    constructor() {
        super(...arguments);
        // 普通输入框的值
        this.selectedValue = [];
        // 选人控件的值
        this.selectedStaffSelectorValue = [];
        // 选人控件框输入的值
        this.selectedStaffSelectorListValue = [];
        this.staffSelectorCacheData = {};
        this.currentSelectedValue = '';
        this.StaffSelectorValue = StaffSelectorValue;
        this.isInitLoaded = false;
        this.selectList = [
            StaffSelectorValue.CurrentLoginUser,
            StaffSelectorValue.CurrentLoginDept,
            StaffSelectorValue.OrgUser,
            StaffSelectorValue.OrgDept,
        ];
        this.orginalList = {
            CurrentLoginUser: {
                id: StaffSelectorValue.CurrentLoginUser,
                name: '当前登录人',
            },
            CurrentLoginDept: {
                id: StaffSelectorValue.CurrentLoginDept,
                name: '当前登录人部门',
            },
            OrgDept: {
                id: StaffSelectorValue.OrgDept,
                name: '从组织机构中选择部门',
            },
            OrgUser: {
                id: StaffSelectorValue.OrgUser,
                name: '从组织机构中选择人员',
            },
        };
        this.selectData = [];
        this.isLoadedStaff = false;
    }
    get isCurrentLogin() {
        return this.selectedValue.some((item) => {
            return item.id === StaffSelectorValue.CurrentLoginUser || item.id === StaffSelectorValue.CurrentLoginDept;
        });
    }
    // 切换字段清空选中的值
    onCodeValueChange() {
        this.selectedValue = [];
        this.currentSelectedValue = '';
        this.generateSelectData(this.condition);
    }
    // 切换关系符清空选中的值，并更新值域的下拉列表
    onConditionOperatorValueChange() {
        this.selectedValue = [];
        this.currentSelectedValue = '';
        this.generateSelectData(this.condition);
    }
    // 回选值变化时更新选中的值
    async onConditionValueValueChange(conditionValue) {
        this.setCurrentSelectedValue();
        const isStaffSelectorValue = conditionValue.every((item) => {
            return item.id !== StaffSelectorValue.CurrentLoginUser && item.id !== StaffSelectorValue.CurrentLoginDept;
        });
        // 是否是选人控件的值
        if (isStaffSelectorValue) {
            // 选人控件的输入值
            this.selectedStaffSelectorValue = conditionValue;
            const isLoaded = conditionValue.every(item => item.selectedNode);
            if (!isLoaded) {
                // 权限配置的输入值
                const needLoadData = [];
                for (const item of conditionValue) {
                    const exsistData = this.staffSelectorCacheData[item.id];
                    if (!exsistData) {
                        needLoadData.push(item);
                    }
                    else {
                        item.selectedNode = JSON.parse(exsistData);
                    }
                }
                if (needLoadData.length) {
                    this.inputLoading = true;
                    const res = await this.getOrgInfo(conditionValue);
                    for (const item of conditionValue) {
                        const resData = res.find(data => data.id === item.id);
                        if (resData) {
                            item.selectedNode = resData;
                            this.staffSelectorCacheData[item.id] = JSON.stringify(resData);
                        }
                    }
                }
            }
            // 权限配置的输入值
            this.selectedStaffSelectorListValue = conditionValue.map(item => item.selectedNode);
        }
        else {
            this.selectedValue = conditionValue;
        }
        this.inputLoading = false;
    }
    /**
     * 设置选中的值域
     */
    setCurrentSelectedValue() {
        const conditionValue = this.condition.conditionValue;
        for (const item of conditionValue) {
            const value = this.selectList.find(id => id === item.id);
            if (value) {
                this.currentSelectedValue = value;
            }
            else {
                const isUser = item.type === UnitType.User;
                if (isUser) {
                    this.currentSelectedValue = StaffSelectorValue.OrgUser;
                }
                else {
                    this.currentSelectedValue = StaffSelectorValue.OrgDept;
                }
            }
        }
    }
    /**
     * 选择单击登录人或当前登录人部门、从组织机构选择人员或从组织机构选择部门
     */
    handlerChange(item) {
        if (item.id === StaffSelectorValue.OrgUser || item.id === StaffSelectorValue.OrgDept) {
            // 从组织机构中选中人员或部门
            if (item.id === StaffSelectorValue.OrgUser) {
                this.condition.staffSelectorOptions.selectDept = false;
                this.condition.staffSelectorOptions.selectUser = true;
            }
            else {
                this.condition.staffSelectorOptions.selectDept = true;
                this.condition.staffSelectorOptions.selectUser = false;
            }
            const isSamePrevSelectedValue = this.currentSelectedValue === item.id;
            // 如果选中的是不同类型的值，则需要把当前选中的清空
            if (!isSamePrevSelectedValue) {
                this.selectedValue = [];
                this.condition.conditionValue = [];
            }
            this.$refs.staffSelector.showModal();
        }
        else {
            // 当前登录人或当前登录人部门
            const value = { id: item.id, name: item.name };
            if (item.id === StaffSelectorValue.CurrentLoginUser) {
                value.type = UnitType.User;
            }
            else {
                value.type = UnitType.Dept;
            }
            this.condition.conditionValue = [];
            this.$set(this.condition.conditionValue, 0, value);
        }
        this.currentSelectedValue = item.id;
    }
    ok(values) {
        // 缓存添加的数据
        for (const item of values) {
            const exsistData = this.staffSelectorCacheData[item.id];
            if (!exsistData) {
                this.staffSelectorCacheData[item.id] = JSON.stringify(item.selectedNode);
            }
            else {
                if (JSON.stringify(exsistData) !== JSON.stringify(item.selectedNode)) {
                    this.staffSelectorCacheData[item.id] = JSON.stringify(item.selectedNode);
                }
            }
        }
        this.condition.conditionValue = values;
    }
    /**
     * 确定、关闭弹窗，如果没有选中值，则选中值为默认值，即为空字符串
     */
    close() {
        if (!this.condition.conditionValue.length) {
            this.currentSelectedValue = '';
        }
    }
    /**
     * 删除选中的选人控件项
     */
    remove(index) {
        this.isInitLoaded = false;
        this.condition.conditionValue.splice(index, 1);
        this.close();
    }
    includeOperator(operatorList, operator) {
        return operatorList.some((item) => item.value === operator);
    }
    /**
     * 获取值域的下拉列表
     */
    generateSelectData(condition) {
        this.$set(this, 'selectData', []);
        const conditionOperatorValue = condition.conditionOperatorValue;
        /** *人员单选***/
        if (condition.valueType === ValueType.StaffPicker) {
            if (condition.isSystemProperty) {
                // 等于或不等于
                if (this.includeOperator(EqualOrNotEqual, conditionOperatorValue)) {
                    this.selectData.splice(0, 0, this.orginalList.OrgUser);
                }
                // 属于或不属于
                if (this.includeOperator(OfOrNotOf, conditionOperatorValue)) {
                    this.selectData.splice(0, 0, this.orginalList.OrgUser, this.orginalList.OrgDept);
                }
            }
            else {
                // 等于或不等于
                if (this.includeOperator(EqualOrNotEqual, conditionOperatorValue)) {
                    this.selectData.splice(0, 0, this.orginalList.CurrentLoginUser, this.orginalList.OrgUser);
                }
                // 属于或不属于
                if (this.includeOperator(OfOrNotOf, conditionOperatorValue)) {
                    this.selectData.splice(0, 0, this.orginalList.CurrentLoginDept, this.orginalList.OrgUser, this.orginalList.OrgDept);
                }
            }
        }
        /** *人员多选** */
        if (condition.valueType === ValueType.StaffMultiPicker) {
            if (condition.isSystemProperty) {
                // 包含与不包含
                if (this.includeOperator(ContainOrNotContain, conditionOperatorValue)) {
                    this.selectData.splice(0, 0, this.orginalList.OrgUser);
                }
                // 属于或不属于
                if (this.includeOperator(OfOrNotOf, conditionOperatorValue)) {
                    this.selectData.splice(0, 0, this.orginalList.OrgUser, this.orginalList.OrgDept);
                }
            }
            else {
                // 包含与不包含
                if (this.includeOperator(ContainOrNotContain, conditionOperatorValue)) {
                    this.selectData.splice(0, 0, this.orginalList.CurrentLoginUser, this.orginalList.OrgUser);
                }
                // 属于或不属于
                if (this.includeOperator(OfOrNotOf, conditionOperatorValue)) {
                    this.selectData.splice(0, 0, this.orginalList.CurrentLoginDept, this.orginalList.OrgUser, this.orginalList.OrgDept);
                }
            }
        }
        /** *部门单选** */
        if (condition.valueType === ValueType.DeptPicker) {
            if (condition.isSystemProperty) {
                // 等于或不等于
                if (this.includeOperator(EqualOrNotEqual, conditionOperatorValue)) {
                    this.selectData.splice(0, 0, this.orginalList.OrgDept);
                }
                // 属于或不属于
                if (this.includeOperator(OfOrNotOf, conditionOperatorValue)) {
                    this.selectData.splice(0, 0, this.orginalList.OrgDept);
                }
                // 包含与不包含
                if (this.includeOperator(ContainOrNotContain, conditionOperatorValue)) {
                    this.selectData.splice(0, 0, this.orginalList.OrgUser, this.orginalList.OrgDept);
                }
            }
            else {
                // 等于或不等于
                if (this.includeOperator(EqualOrNotEqual, conditionOperatorValue)) {
                    this.selectData.splice(0, 0, this.orginalList.CurrentLoginDept, this.orginalList.OrgDept);
                }
                // 属于或不属于
                if (this.includeOperator(OfOrNotOf, conditionOperatorValue)) {
                    this.selectData.splice(0, 0, this.orginalList.CurrentLoginDept, this.orginalList.OrgDept);
                }
                // 包含与不包含
                if (this.includeOperator(ContainOrNotContain, conditionOperatorValue)) {
                    this.selectData.splice(0, 0, this.orginalList.CurrentLoginUser, this.orginalList.CurrentLoginDept, this.orginalList.OrgUser, this.orginalList.OrgDept);
                }
            }
        }
        /** *部门多选***/
        if (condition.valueType === ValueType.DeptMultiPicker) {
            if (condition.isSystemProperty) {
                // 属于或不属于
                if (this.includeOperator(OfOrNotOf, conditionOperatorValue)) {
                    this.selectData.push(this.orginalList.OrgDept);
                }
                // 包含与不包含
                if (this.includeOperator(ContainOrNotContain, conditionOperatorValue)) {
                    this.selectData.push(this.orginalList.OrgUser, this.orginalList.OrgDept);
                }
            }
            else {
                // 属于或不属于
                if (this.includeOperator(OfOrNotOf, conditionOperatorValue)) {
                    this.selectData.push(this.orginalList.CurrentLoginDept, this.orginalList.OrgDept);
                }
                // 包含与不包含
                if (this.includeOperator(ContainOrNotContain, conditionOperatorValue)) {
                    this.selectData.push(this.orginalList.CurrentLoginDept, this.orginalList.OrgUser, this.orginalList.OrgDept);
                }
            }
        }
    }
};
__decorate([
    Watch('condition.code', { immediate: true })
], CStaffSelector.prototype, "onCodeValueChange", null);
__decorate([
    Watch('condition.conditionOperatorValue', { immediate: true })
], CStaffSelector.prototype, "onConditionOperatorValueChange", null);
__decorate([
    Watch('condition.conditionValue', { immediate: true, deep: true })
], CStaffSelector.prototype, "onConditionValueValueChange", null);
CStaffSelector = __decorate([
    Component({
        name: 'CStaffSelector',
        components: {
            StaffSelector,
            StaffSelectorInput,
        },
    })
], CStaffSelector);
export default CStaffSelector;
