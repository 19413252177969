import { __decorate } from "tslib";
import { Component, Prop } from 'vue-property-decorator';
import DataAuth from '../../../common-comp/data-auth';
import { ConditionItem } from '../../../common-comp/condition-comp';
let CustomDataAuthSet = class CustomDataAuthSet extends DataAuth {
    constructor() {
        super(...arguments);
        this.visible = false;
    }
    /**
     * 添加自定义数据行
     */
    addCustomCondition() {
        super.addCondition();
    }
    /**
     * 删除自定义数据行
     */
    delCustomCondition(condition) {
        super.delCondition(condition);
    }
    /**
     * 切换自定义数据行字段
     */
    onChangeCustomField(code) {
        super.onChangeField(code);
    }
    /**
     * 切换自定义数据行关系符
     */
    onChangeCustomOperator(value) {
        super.onChangeOperator(value);
    }
    /**
     * 选中自定义数据行
     */
    onSelectCustomField(item) {
        super.onSelectField(item);
    }
};
__decorate([
    Prop()
], CustomDataAuthSet.prototype, "isNonConditionError", void 0);
CustomDataAuthSet = __decorate([
    Component({
        name: 'CustomDataAuthSet',
        components: {
            ConditionItem,
        },
    })
], CustomDataAuthSet);
export default CustomDataAuthSet;
