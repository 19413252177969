import { TreeNodeType } from '@/typings/app-auth';
import AppData from './app-data';
import DataItem from '@/components/app-manage/auth-config-set/common-comp/data-auth/data-item';
import TreeUtils from '@/common/TreeUtils';
export default class AppDataDetail extends AppData {
    constructor() {
        super(...arguments);
        /* *************设置详情**************** */
        // 应用权限详情数据
        this.appAuthDetailData = {};
    }
    get copyAppAuthDetailData() {
        let copyAppAuthDetailData = {};
        try {
            copyAppAuthDetailData = JSON.parse(JSON.stringify(this.appAuthDetailData));
        }
        catch (e) {
            copyAppAuthDetailData = {};
        }
        return copyAppAuthDetailData;
    }
    get menuDetail() {
        return this.copyAppAuthDetailData.permFeatureMenuDto || {};
    }
    // 菜单详情
    get appMenusDetail() {
        const permFeatureMenuDto = this.menuDetail || {};
        return permFeatureMenuDto.menuBarItemsMap || {};
    }
    // 领域模型详情
    get domainModelDetail() {
        const permDomainDto = this.copyAppAuthDetailData.permDomainDto || {};
        return permDomainDto.permDomainDataServiceDtoList || [];
    }
    // 页面详情
    get pagesDetail() {
        const permFeaturePageDto = this.copyAppAuthDetailData.permFeaturePageDto || [];
        return permFeaturePageDto;
    }
    get isEdit() {
        const isMenuEdit = !!this.menuDetail.permId;
        const isDomainModelEdit = this.domainModelDetail.every((item) => {
            return !!item.permDomainId;
        });
        const isPageEdit = this.pagesDetail.every((item) => {
            return !!item.permId;
        });
        return isMenuEdit && isDomainModelEdit && isPageEdit;
    }
    /**
     * 设置详情到树数据
     */
    setDetailDataToTreeData() {
        this.treeData.forEach((treeNode) => {
            if (treeNode.nodeType === TreeNodeType.MenuAuth) {
                this.setDetailDataToMenu(treeNode.menuTree);
            }
            else if (treeNode.nodeType === TreeNodeType.GroupAuth) {
                const groupList = treeNode.children || [];
                this.setDetailDataToGroup(groupList);
            }
        });
    }
    /**
     * 设置分组的详情
     * @param groupList
     */
    setDetailDataToGroup(groupList = []) {
        let allPages = [];
        groupList.forEach((group) => {
            const moduleTree = group.moduleTree;
            const domainModels = moduleTree[0].children;
            const pages = moduleTree[1].children;
            this.setDetailDataToDomainModels(domainModels);
            for (const detail of this.pagesDetail) {
                if (group.code === detail.groupCode) {
                    group.pageAuthPermId = detail.permId;
                    break;
                }
            }
            allPages = [...allPages, ...pages];
        });
        this.setDetailDataToPages(allPages);
    }
    /**
     * 设置菜单的详情
     * @param menuTree
     */
    setDetailDataToMenu(menuTree = []) {
        const menuKeys = Object.keys(this.appMenusDetail);
        menuKeys.forEach((key) => {
            menuTree.forEach((menu) => {
                if (menu.key === key) {
                    const checkedKeys = this.appMenusDetail[key];
                    const filterCheckedKeys = [];
                    super.forEachMenuTree(menu.tree, (m) => {
                        if (checkedKeys.includes(m.key)) {
                            filterCheckedKeys.push(m.key);
                        }
                    });
                    if (!this.menuActiveKey.includes(key)) {
                        this.menuActiveKey.push(key);
                    }
                    if (menu.nodeNumber === filterCheckedKeys.length) {
                        menu.checked = true;
                        menu.indeterminate = false;
                    }
                    else {
                        menu.checked = false;
                        menu.indeterminate = true;
                    }
                    menu.checkedKeys = filterCheckedKeys;
                    TreeUtils.forEachTree(menu.tree, (item) => {
                        const isChecked = item.children.every(c => menu.checkedKeys.includes(c.code));
                        if (!isChecked) {
                            const index = menu.checkedKeys.findIndex((c) => {
                                return c === item.code;
                            });
                            if (index >= 0) {
                                menu.checkedKeys.splice(index, 1);
                            }
                        }
                    });
                }
            });
        });
    }
    /**
     * 设置领域模型的详情
     * @param domainModels
     */
    setDetailDataToDomainModels(domainModels) {
        domainModels.forEach((domainModel) => {
            const domainModelDetail = this.domainModelDetail.find((detail) => {
                return detail.domainCode === domainModel.code;
            });
            // 如果已保存的数据在领域模型中已经被删除，则直接放弃
            if (!domainModelDetail)
                return;
            if (domainModelDetail.permDomainId) {
                domainModel.permDomainId = domainModelDetail.permDomainId;
            }
            domainModel.bizSchemas.forEach((schemas) => {
                const modelDataDtoMap = domainModelDetail.modelDataDtoMap || {};
                const schemasKeys = Object.keys(modelDataDtoMap);
                // 模型的详情
                const schemasCode = schemasKeys.find((key) => {
                    return key === schemas.code;
                }) || '';
                // 如果不存在模型编码，则说明有新添加的模型
                if (!schemasCode)
                    return;
                const schemasDetail = modelDataDtoMap[schemasCode];
                const serviceData = schemas.serviceData;
                // 回填自定义数据权限的详情
                const conditionDataAuthDetail = schemasDetail?.modelDataRowDto;
                if (conditionDataAuthDetail) {
                    serviceData.dataAuth = conditionDataAuthDetail.rowType;
                    if (serviceData.dataAuth === 5) {
                        serviceData.customAuthData = this.getConditionAuthDataDetail(conditionDataAuthDetail, schemas.conditionField);
                    }
                }
                // 回填操作权限的详情
                const operateAuthDetail = schemasDetail.modelDataOperatDtoList;
                if (operateAuthDetail) {
                    serviceData.operateAuth = operateAuthDetail.map((operateAuth) => operateAuth.triggerType);
                }
                // 回填高级权限的详情
                const advanceAuthDataDetail = schemasDetail.modelDataHighDtoList;
                if (advanceAuthDataDetail) {
                    serviceData.advanceAuthData = this.getAdvanceAuthDataDetail(advanceAuthDataDetail, schemas.advanceConditionField, schemas.ruleField);
                }
                // 回填字段权限
                this.setDetailDataToPropertes(schemas.properties, schemasDetail.modelDataColDtoList);
            });
        });
    }
    /**
     * 把详情设置到字段权限
     * @param properties
     * @param schemasDetail
     */
    setDetailDataToPropertes(properties = [], fieldAuths = []) {
        properties.forEach((item) => {
            const authCheckboxDetail = fieldAuths.find((fieldAuth) => fieldAuth.colName === item.code);
            // 如果已保存的字段权限在数据项中已被删除，则放弃
            if (!authCheckboxDetail)
                return;
            // 回显字段权限的复选框的值
            this.setFieldAuthTableCheckbox(item, authCheckboxDetail);
        });
    }
    /**
     * 回显字段权限和高级权限结果表格的复选框的值
     * @param field
     * @param authCheckboxDetail
     */
    setFieldAuthTableCheckbox(field, authCheckboxDetail) {
        const authCheckbox = field.fieldAuthCheckbox;
        const editTableWritable = field.editTableWritable;
        // 回填字段详情
        for (const checkKey in authCheckbox) {
            const checkbox = authCheckbox[checkKey];
            if ((authCheckboxDetail.perm & 1) === checkbox.value) {
                checkbox.checked = true;
            }
            // 只允许可写同时可编辑字段回填
            if ((authCheckboxDetail.perm & 2) === checkbox.value && editTableWritable) {
                checkbox.checked = true;
            }
        }
    }
    /**
     * 设置高级权限的详情
     * @param advanceAuthDataDetail
     * @param conditionField
     * @param ruleField
     */
    getAdvanceAuthDataDetail(advanceAuthDataDetail = [], conditionField = {}, ruleField = {}) {
        // 回填高级权限详情数据
        const advanceAuthData = [];
        advanceAuthDataDetail.forEach((detail) => {
            const advanceAuthItem = {
                advanceResultData: [],
                conditionDataAuth: {
                    condition: 'and',
                    selectedConditionList: [],
                },
            };
            // 条件数据
            const conditionData = {
                condition: detail.condition,
                dataRowDtos: detail.dataRowDtos,
            };
            advanceAuthItem.conditionDataAuth = this.getConditionAuthDataDetail(conditionData, conditionField);
            // 回填规则的结果
            const modelDataColDtoList = detail.modelDataColDtoList || [];
            const advanceResultData = [];
            modelDataColDtoList.forEach((resultDetail) => {
                // 数据项
                const fieldList = [
                    ...ruleField.systemField,
                    ...ruleField.businessField,
                ];
                const field = fieldList.find((fieldItem) => {
                    return fieldItem.code === resultDetail.colName;
                });
                // 如果已保存的规则在数据项中已被删除，则放弃
                if (!field)
                    return;
                const copyField = JSON.parse(JSON.stringify(field));
                // 回显结果表格复选框的值
                this.setFieldAuthTableCheckbox(copyField, resultDetail);
                advanceResultData.push(copyField);
            });
            advanceAuthItem.advanceResultData = advanceResultData;
            advanceAuthData.push(advanceAuthItem);
        });
        return advanceAuthData;
    }
    /**
     * 设置条件的详情
     * @param fieldAuth
     * @param conditionField
     */
    getConditionAuthDataDetail(fieldAuth = {}, conditionField = {}) {
        const conditionAuthData = {
            condition: fieldAuth.condition,
            selectedConditionList: [],
        };
        const dataRowDtos = fieldAuth.dataRowDtos || [];
        dataRowDtos.forEach((detail, index) => {
            const fieldList = [
                ...conditionField.systemField,
                ...conditionField.businessField,
            ];
            // 数据项
            const field = fieldList.find((fieldItem) => {
                return fieldItem.code === detail.colName;
            });
            // 如果已保存的条件在数据项中已经被删除，则直接放弃
            if (!field)
                return;
            // 单条条件数据
            const conditionData = this.conditionData(field, fieldList, detail);
            const data = {
                rowIndex: index,
                code: detail.colName,
                ...conditionData,
            };
            conditionAuthData.selectedConditionList.push(data);
        });
        return conditionAuthData;
    }
    /**
     * 自定义权限错误信息
     * @param fieldList
     */
    getCustomConditionErrorMessMap(fieldList = []) {
        const mess = fieldList.map((item) => {
            return {
                code: item.code,
                message: `${item.name}不能为空`,
            };
        });
        const messMap = {};
        mess.forEach((item) => {
            messMap[item.code] = item.message;
        });
        return messMap;
    }
    /**
     * 一行条件的数据
     */
    conditionData(item, fieldList, detailData) {
        const operator = DataItem.getOperator(item);
        if (!Array.isArray(operator))
            return {};
        if (!operator.length)
            return {};
        const selectData = DataItem.getSelectData(item);
        const defaultValue = DataItem.getDefaultValue(item);
        let componetType = this.getComponentType(item);
        let defaultOperator = operator[0].value;
        let conditionValue = '';
        // 如果是编辑的话
        if (detailData) {
            conditionValue = detailData.value;
            // 选人控件
            if (DataItem.isStaffSelector(item)) {
                conditionValue =
                    detailData.subjectList.map((staff) => {
                        return {
                            name: staff.name,
                            id: staff.id,
                            type: staff.type,
                        };
                    }) || [];
            }
            // 时间、日期、日期时间
            if (DataItem.isTime(item) ||
                DataItem.isDate(item) ||
                DataItem.isDateTime(item)) {
                if (DataItem.isTime(item) || DataItem.isDate(item)) {
                    const dateTime = typeof detailData.value === 'string' ? detailData.value.split(' ') : [];
                    if (DataItem.isDate(item)) {
                        detailData.value = dateTime[0];
                    }
                    else if (DataItem.isTime(item)) {
                        detailData.value = dateTime[1];
                    }
                }
                conditionValue = DataItem.getMomentDate(detailData.value, item.format);
            }
            // 下拉框选项
            if (DataItem.isSelect(item)) {
                if (Array.isArray(defaultValue)) {
                    conditionValue = typeof detailData.value === 'string' ? detailData.value.split(',') : [];
                }
            }
            defaultOperator = detailData.logicOperta;
            const isNullOrNotNull = DataItem.isNullOrNotNull(detailData.logicOperta);
            // 空或非空
            if (isNullOrNotNull) {
                const nullDefaultValue = DataItem.getDefaultValue(item);
                componetType = {};
                conditionValue = nullDefaultValue;
            }
        }
        else {
            conditionValue = defaultValue;
        }
        let precision = 0;
        let int = 0;
        // 整数位数和小数位数
        if (DataItem.isDecimal(item) || DataItem.isInt(item)) {
            const format = item.format ? item.format.split('.') : [];
            int = format[0].length;
            if (DataItem.isDecimal(item)) {
                precision = format[1].length;
            }
        }
        const customConditionErrorMessMap = this.getCustomConditionErrorMessMap(fieldList);
        const errorInfo = {
            isError: false,
            message: customConditionErrorMessMap[item.code],
        };
        const result = {
            name: item.name,
            code: item.code,
            format: item.format,
            uiControl: item.uiControl,
            valueType: item.valueType,
            propertyLength: item.propertyLength,
            isSystemProperty: item.isSystemProperty,
            precision: precision,
            int: int,
            conditionOperatorValue: defaultOperator,
            conditionValue: conditionValue,
            componetType: componetType,
            staffSelectorOptions: DataItem.getStaffSelectorOpts(item, defaultOperator),
            operatorData: operator,
            selectData: selectData,
            errorInfo: errorInfo,
        };
        return result;
    }
    /**
     * 条件行的组件类型
     */
    getComponentType(item) {
        return {
            isInput: DataItem.isInput(item),
            isDate: DataItem.isDate(item),
            isDateTime: DataItem.isDateTime(item),
            isTime: DataItem.isTime(item),
            isInt: DataItem.isInt(item),
            isDecimal: DataItem.isDecimal(item),
            isSelect: DataItem.isSelect(item),
            isStaffSelector: DataItem.isStaffSelector(item),
        };
    }
    /**
     * 设置页面权限的详情
     * @param pages
     */
    setDetailDataToPages(pages = []) {
        const pagesDetail = this.pagesDetail;
        let allPagesDetail = {};
        pagesDetail.forEach((detail) => {
            const pageGroupList = detail.pageGroupList || [];
            pageGroupList.forEach((pageMapItem) => {
                allPagesDetail = { ...allPagesDetail, ...pageMapItem.pageMap };
            });
        });
        pages.forEach((page) => {
            const detailComps = allPagesDetail[page.code];
            // 如果已保存的页面已被删除，则放弃
            if (!detailComps)
                return;
            if (!Array.isArray(detailComps))
                return;
            page.isCheckedPage = true;
            const components = page.components || [];
            components.forEach((comp) => {
                const detailComp = detailComps.find((detailCompItem) => detailCompItem.componentCode === comp.code);
                // 如果已经保存的组件已被删除，则放弃
                if (!detailComp)
                    return;
                const authCheckbox = comp.componentAuthCheckbox;
                for (const checkKey in authCheckbox) {
                    const checkbox = authCheckbox[checkKey];
                    if ((detailComp.perm & 1) === checkbox.value) {
                        checkbox.checked = true;
                    }
                    if ((detailComp.perm & 2) === checkbox.value) {
                        checkbox.checked = true;
                    }
                }
            });
        });
    }
}
