import { __decorate } from "tslib";
import { Component, Vue, Prop, Emit, Watch, } from 'vue-property-decorator';
import AddRule from './add-rule.vue';
let AdvanceDataAuth = class AdvanceDataAuth extends Vue {
    constructor() {
        super(...arguments);
        this.isShowRuleWrapper = true;
        this.isShowRule = false;
        this.conditionDataAuth = {
            condition: 'and',
            selectedConditionList: [],
        };
        this.operateType = 0; // 0：新增 1：编辑
        this.curOperateIndex = 0;
        this.advanceResultData = [];
        this.ruleList = [];
    }
    onAdvanceAuthDataChange(advanceAuthData = []) {
        const ruleList = [];
        for (let i = 0, len = advanceAuthData.length; i < len; i++) {
            const item = advanceAuthData[i];
            const selectedConditionList = item.conditionDataAuth.selectedConditionList;
            const advanceResultData = item.advanceResultData;
            // 拼接条件的名称
            const conditionName = selectedConditionList
                .map((data) => {
                return data.name;
            })
                .join(';');
            // 拼接结果的名称
            const resultName = advanceResultData
                .map((data) => {
                return data.name;
            })
                .join(';');
            const newRule = {
                operateIndex: i,
                index: i + 1,
                title: '规则',
                conditionName: conditionName,
                resultName: resultName,
                key: `0-${i}`,
                scopedSlots: {
                    title: 'title',
                },
                source: item,
            };
            ruleList.push(newRule);
        }
        this.ruleList = ruleList;
    }
    /**
     * 复制一份数据
     */
    getCopyData(data = []) {
        let result = [];
        try {
            result = JSON.parse(JSON.stringify(data));
        }
        catch (e) {
            Vue.prototype.$errorcatcher.showMessagePopup({
                title: '错误提示！',
                message: [e, '!'],
                displayFooter: false,
                correctGuide: '请检查元数据是否正确',
            });
        }
        return result;
    }
    /**
     * 添加规则
     */
    addRule() {
        this.isShowRule = true;
        this.operateType = 0;
        this.advanceResultData = [];
        this.conditionDataAuth = {
            condition: 'and',
            selectedConditionList: [],
        };
        this.curOperateIndex = this.ruleList.length;
    }
    /**
     * 编辑规则
     */
    editRule(rule) {
        const { source, operateIndex } = rule;
        this.isShowRule = true;
        this.operateType = 1;
        this.advanceResultData = source.advanceResultData;
        this.conditionDataAuth = source.conditionDataAuth;
        this.curOperateIndex = operateIndex;
    }
    /**
     * 移除数组中指定的规则
     */
    removeDataItemByKey(ruleList, key, callback = function () { }) {
        for (let i = 0, len = ruleList.length; i < len; i++) {
            const item = ruleList[i];
            if (item.key === key) {
                callback(i);
                ruleList.splice(i, 1);
                break;
            }
        }
    }
    /**
     * 重新生成规则数组
     */
    resetData(ruleList = []) {
        if (!Array.isArray(ruleList))
            return [];
        const res = ruleList.map((item, index) => {
            return {
                ...item,
                operateIndex: index,
                index: index + 1,
            };
        });
        return res;
    }
    /**
     * 经过删除、排序后更新最新的数据
     */
    getAdvanceAuthData(data) {
        const ruleList = this.resetData(data);
        this.ruleList = ruleList;
        const advanceAuthData = ruleList.map((item) => {
            return item.source;
        });
        return {
            schemasCode: this.schemasCode,
            advanceAuthData: advanceAuthData,
        };
    }
    /**
     * 删除规则
     */
    del(record) {
        const ruleList = JSON.parse(JSON.stringify(this.ruleList));
        this.removeDataItemByKey(ruleList, record.key);
        const advanceAuthData = this.getAdvanceAuthData(ruleList);
        this.updateAdvanceAuthData(advanceAuthData);
    }
    /**
     * 规则向上排序
     */
    upSort(record) {
        if (record.index === 1)
            return;
        const ruleList = JSON.parse(JSON.stringify(this.ruleList));
        let dropPosition = 0;
        this.removeDataItemByKey(ruleList, record.key, (i) => {
            dropPosition = i - 1;
        });
        ruleList.splice(dropPosition, 0, record);
        const advanceAuthData = this.getAdvanceAuthData(ruleList);
        this.updateAdvanceAuthData(advanceAuthData);
    }
    /**
     * 规则拖拽排序
     */
    onDrop(e) {
        // 拖入的位置
        let dropPosition = e.dropPosition;
        // 拖拽的对象
        const dragObj = e.dragNode;
        // 拖拽的对象的key
        const dragKey = dragObj.eventKey;
        const ruleList = JSON.parse(JSON.stringify(this.ruleList));
        if (e.dropToGap) {
            dropPosition = dropPosition - 1;
        }
        this.removeDataItemByKey(ruleList, dragKey);
        ruleList.splice(dropPosition, 0, dragObj.dataRef);
        const advanceAuthData = this.getAdvanceAuthData(ruleList);
        this.updateAdvanceAuthData(advanceAuthData);
    }
    updateAdvanceAuthData(data) {
        return data;
    }
};
__decorate([
    Prop({ default: '' })
], AdvanceDataAuth.prototype, "schemasCode", void 0);
__decorate([
    Prop({ default: () => [] })
], AdvanceDataAuth.prototype, "advanceAuthData", void 0);
__decorate([
    Prop({ default: () => { } })
], AdvanceDataAuth.prototype, "conditionField", void 0);
__decorate([
    Prop({ default: () => { } })
], AdvanceDataAuth.prototype, "ruleField", void 0);
__decorate([
    Watch('advanceAuthData', { immediate: true })
], AdvanceDataAuth.prototype, "onAdvanceAuthDataChange", null);
__decorate([
    Emit('updateAdvanceAuthData')
], AdvanceDataAuth.prototype, "updateAdvanceAuthData", null);
AdvanceDataAuth = __decorate([
    Component({
        name: 'AdvanceDataAuth',
        components: {
            AddRule,
        },
    })
], AdvanceDataAuth);
export default AdvanceDataAuth;
