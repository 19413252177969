import { __decorate } from "tslib";
import { Component, Prop, Watch, Emit, } from 'vue-property-decorator';
import DataAuth from '../../../common-comp/data-auth';
import { StaffSelector } from '@cloudpivot-hermes/common-components';
import { ConditionItem } from '../../../common-comp/condition-comp';
import FieldAuthTable from './field-auth.vue';
import * as Utils from '@/common/utils';
let AddRule = class AddRule extends DataAuth {
    constructor() {
        super(...arguments);
        this.visible = false;
        this.title = '新增规则';
        this.resultList = [];
        this.curRuleFieldCode = '';
        this.curRuleFieldRowIndex = 0;
        this.ruleFields = {
            systemField: {
                groupName: '系统数据项',
                fieldList: [],
            },
            businessField: {
                groupName: '业务数据项',
                fieldList: [],
            },
        };
        this.ruleValueList = [
            {
                name: '可见',
                value: 1,
            },
            {
                name: '不可见',
                value: 2,
            },
        ];
    }
    get ruleFieldList() {
        const fieldList = [
            ...this.ruleFields.systemField.fieldList,
            ...this.ruleFields.businessField.fieldList,
        ];
        return fieldList;
    }
    async onVisibleChange(v) {
        this.visible = v;
        if (v) {
            this.resetAdvanceDataAuth();
            this.onRuleFieldChange();
            if (this.operateType === 0) {
                this.title = '新增规则';
            }
            else {
                this.title = '编辑规则';
                this.fillbackResultDataDetail();
            }
        }
    }
    onRuleFieldChange() {
        this.ruleFields.systemField.fieldList = this.ruleField.systemField || [];
        this.ruleFields.businessField.fieldList =
            this.ruleField.businessField || [];
    }
    /**
     * 添加条件
     */
    addAdvanceCondition() {
        super.addCondition();
    }
    /**
     * 删除条件
     */
    delAdvanceCondition(condition) {
        super.delCondition(condition);
    }
    /**
     * 切换条件字段
     */
    onChangeAdvanceField(code) {
        super.onChangeField(code);
    }
    /**
     * 切换关系符（链接符）
     */
    onChangeAdvanceOperator(value) {
        super.onChangeOperator(value);
    }
    /**
     * 选中条件行
     */
    onSelectAdvanceField(item) {
        super.onSelectField(item);
    }
    fillbackResultDataDetail() {
        const advanceResultData = this.advanceResultData || [];
        if (!Array.isArray(advanceResultData))
            return;
        for (const item of advanceResultData) {
            const field = this.ruleFieldList.find(fieldItem => fieldItem.code === item.code);
            if (field)
                field.isAddedResult = true;
        }
        this.resultList = advanceResultData;
    }
    /**
     * 添加结果行
     */
    addAdvanceResult() {
        const fields = this.ruleFieldList;
        let item = fields.find((field) => !field.isAddedResult);
        if (!item)
            return;
        item.isAddedResult = true;
        item = JSON.parse(JSON.stringify(item));
        const data = {
            ...item,
        };
        this.resultList.push(data);
    }
    /**
     * 删除结果行
     */
    delAdvanceResult(item) {
        const resultList = this.resultList;
        const ruleFieldList = this.ruleFieldList;
        const conditionField = ruleFieldList.find((field) => field.code === item.code);
        if (conditionField) {
            conditionField.isAddedResult = false;
        }
        resultList.splice(item.index, 1);
    }
    onSelectRuleField(record) {
        this.curRuleFieldCode = record.code;
        this.curRuleFieldRowIndex = record.index;
    }
    /**
     * 切换规则字段
     */
    onChangeRuleField(code) {
        const ruleFieldList = this.ruleFieldList;
        const resultList = this.resultList;
        const curConditionField = ruleFieldList.find((field) => field.code === this.curRuleFieldCode);
        if (curConditionField) {
            curConditionField.isAddedResult = false;
        }
        let conditionField = ruleFieldList.find((field) => field.code === code);
        if (!conditionField)
            return;
        conditionField.isAddedResult = true;
        conditionField = JSON.parse(JSON.stringify(conditionField));
        const data = {
            ...conditionField,
        };
        resultList.splice(this.curRuleFieldRowIndex, 1, data);
    }
    isSubmitabled() {
        if (!this.resultList.length) {
            this.$message.error('请添加至少一个结果');
            return false;
        }
        if (this.resultList.length &&
            !this.conditionDataAuth.selectedConditionList.length) {
            this.$message.error('如果添加了结果，则需要添加至少一个条件');
            return false;
        }
        return true;
    }
    resetAdvanceDataAuth() {
        this.resultList = [];
        this.curRuleFieldCode = '';
        this.curRuleFieldRowIndex = 0;
    }
    /**
     * 关闭弹窗
     */
    async cancelAdvanceDataAuth() {
        this.onClose();
        await Utils.sleep(300);
        super.clearDataAuth();
        this.resetAdvanceDataAuth();
    }
    getCopyData(data) {
        return JSON.parse(JSON.stringify(data));
    }
    /**
     * 保存数据
     */
    confirmAdvanceDataAuth() {
        const conditionData = super.confirmAuthData();
        if (!conditionData)
            return;
        const isSubmitabled = this.isSubmitabled();
        if (!isSubmitabled)
            return;
        this.comfirmEvent({
            curOperateIndex: this.curOperateIndex,
            operateType: this.operateType,
            schemasCode: this.schemasCode,
            conditionDataAuth: this.getCopyData(this.conditionDataAuth),
            advanceResultData: this.getCopyData(this.resultList),
        });
        this.cancelAdvanceDataAuth();
    }
    onClose() {
        return false;
    }
    comfirmEvent(data) {
        return data;
    }
};
__decorate([
    Prop()
], AddRule.prototype, "value", void 0);
__decorate([
    Prop({ default: '' })
], AddRule.prototype, "schemasCode", void 0);
__decorate([
    Prop({ default: 0 })
], AddRule.prototype, "operateType", void 0);
__decorate([
    Prop({ default: 0 })
], AddRule.prototype, "curOperateIndex", void 0);
__decorate([
    Prop({ default: () => { } })
], AddRule.prototype, "ruleField", void 0);
__decorate([
    Prop({ default: () => [] })
], AddRule.prototype, "advanceResultData", void 0);
__decorate([
    Watch('value')
], AddRule.prototype, "onVisibleChange", null);
__decorate([
    Emit('input')
], AddRule.prototype, "onClose", null);
__decorate([
    Emit('comfirmEvent')
], AddRule.prototype, "comfirmEvent", null);
AddRule = __decorate([
    Component({
        name: 'AddRule',
        components: {
            StaffSelector,
            ConditionItem,
            FieldAuthTable,
        },
    })
], AddRule);
export default AddRule;
