/**
 * 内置的系统数据项
 */
export var SystemCodeItemType;
(function (SystemCodeItemType) {
    // 创建人
    SystemCodeItemType["CreatedBy"] = "createdBy";
    // 创建时间
    SystemCodeItemType["CreatedTime"] = "createdTime";
    // 创建人部门
    SystemCodeItemType["CreatedDept"] = "createdDept";
    // 修改时间
    SystemCodeItemType["ModifiedTime"] = "modifiedTime";
    // 拥有者
    SystemCodeItemType["Owner"] = "owner";
    // 拥有者部门
    SystemCodeItemType["OwnerDept"] = "ownerDept";
    // 单据号
    SystemCodeItemType["Document"] = "document";
    // 单据状态
    SystemCodeItemType["Status"] = "status";
})(SystemCodeItemType || (SystemCodeItemType = {}));
/**
 * 业务数据项的valueType
 */
export var BusinessDataItemType;
(function (BusinessDataItemType) {
    // 短文本类型
    BusinessDataItemType["ShortText"] = "SHORT_TEXT";
    // 长文本类型
    BusinessDataItemType["LongText"] = "LONG_TEXT";
    // 布尔类型
    BusinessDataItemType["Boolean"] = "BOOLEAN";
    // 整数类型
    BusinessDataItemType["Number"] = "NUMBER";
    // 带小数类型
    BusinessDataItemType["Decimal"] = "DECIMAL";
    // 日期类型
    BusinessDataItemType["Date"] = "DATE";
    // 单选文本框
    BusinessDataItemType["RadioText"] = "RADIO_TEXT";
    // 多选文本框
    BusinessDataItemType["MultiSelectText"] = "MULTI_SELECT_TEXT";
    // 人员单选
    BusinessDataItemType["RadioPerson"] = "RADIO_PERSON";
    // 人员多选
    BusinessDataItemType["MultiSelectPerson"] = "MULTI_SELECT_PERSON";
    // 部门单选
    BusinessDataItemType["RadioDepartment"] = "RADIO_DEPARTMENT";
    // 部门多选
    BusinessDataItemType["MultiSelectDepartment"] = "MULTI_SELECT_DEPARTMENT";
    // 人员或部门混选
    BusinessDataItemType["PersonDepartmentSelect"] = "PERSON_DEPARTMENT_SELECT";
})(BusinessDataItemType || (BusinessDataItemType = {}));
/**
 * 不需要显示系统数据项的code
 */
export var UnShowSystemCodeItemType;
(function (UnShowSystemCodeItemType) {
    // 主键
    UnShowSystemCodeItemType["ID"] = "id";
    // 修改人
    UnShowSystemCodeItemType["ModifiedBy"] = "modifiedBy";
})(UnShowSystemCodeItemType || (UnShowSystemCodeItemType = {}));
/**
 * 不需要显示自定义条件业务数据项的valueType
 */
export var UnShowBusinessDataItemType;
(function (UnShowBusinessDataItemType) {
    // 图片
    UnShowBusinessDataItemType["Image"] = "IMAGE";
    // 附件
    UnShowBusinessDataItemType["Attachment"] = "ATTACHMENT";
    // 地图
    UnShowBusinessDataItemType["MapType"] = "MAP_TYPE";
    // 地址
    UnShowBusinessDataItemType["AddrType"] = "ADDR_TYPE";
    // // 多选文本框
    // MultiSelectText = 'MULTI_SELECT_TEXT',
    // // 单选文本框
    // RadioText = 'RADIO_TEXT',
    // 富文本
    UnShowBusinessDataItemType["RichText"] = "RICH_TEXT";
    // 子模型
    UnShowBusinessDataItemType["RelationKey"] = "RELATION_KEY";
    // // 人员单选
    // RadioPerson = 'RADIO_PERSON',
    // // 人员多选
    // MultiSelectPerson = 'MULTI_SELECT_PERSON',
    // // 部门单选
    // RadioDepartment = 'RADIO_DEPARTMENT',
    // // 部门多选
    // MultiSelectDepartment = 'MULTI_SELECT_DEPARTMENT',
    // // 人员或部门混选
    // PersonDepartmentSelect = 'PERSON_DEPARTMENT_SELECT',
})(UnShowBusinessDataItemType || (UnShowBusinessDataItemType = {}));
/**
 * 不需要显示高级权限条件的业务数据项的valueType
 */
export var UnShowAdvanceBusinessDataItemType;
(function (UnShowAdvanceBusinessDataItemType) {
    // 图片
    UnShowAdvanceBusinessDataItemType["Image"] = "IMAGE";
    // 附件
    UnShowAdvanceBusinessDataItemType["Attachment"] = "ATTACHMENT";
    // 地图
    UnShowAdvanceBusinessDataItemType["MapType"] = "MAP_TYPE";
    // 地址
    UnShowAdvanceBusinessDataItemType["AddrType"] = "ADDR_TYPE";
    // 多选文本框
    // MultiSelectText = 'MULTI_SELECT_TEXT',
    // // 单选文本框
    // RadioText = 'RADIO_TEXT',
    // 富文本
    UnShowAdvanceBusinessDataItemType["RichText"] = "RICH_TEXT";
    // 子模型
    UnShowAdvanceBusinessDataItemType["RelationKey"] = "RELATION_KEY";
    // 人员单选
    // RadioPerson = 'RADIO_PERSON',
    // 人员多选
    // MultiSelectPerson = 'MULTI_SELECT_PERSON',
    // 部门单选
    // RadioDepartment = 'RADIO_DEPARTMENT',
    // 部门多选
    // MultiSelectDepartment = 'MULTI_SELECT_DEPARTMENT',
    // 人员或部门混选
    UnShowAdvanceBusinessDataItemType["PersonDepartmentSelect"] = "PERSON_DEPARTMENT_SELECT";
})(UnShowAdvanceBusinessDataItemType || (UnShowAdvanceBusinessDataItemType = {}));
