import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { Empty } from '@h3/antd-vue';
let EmptyPage = class EmptyPage extends Vue {
    constructor() {
        super(...arguments);
        this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
    }
};
EmptyPage = __decorate([
    Component({
        name: 'EmptyPage',
    })
], EmptyPage);
export default EmptyPage;
