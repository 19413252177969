import { BusinessDataItemType, SystemCodeItemType, } from './data-item-condition-type';
import { ValueType, StringDefaultValue, UndefinedDefaultValue, ArrayDefaultValue, } from './data-item-value-type';
import * as SelectList from './data-item-select-list';
import moment from 'moment';
import { DateItemOperatorType, Equal, EqualOrNotEqual, ContainOrNotContain, NullOrNotNull, GreaterThanOrLessThan, LessThanEqualOrGreaterThanEqual, OfOrNotOf, } from './data-item-operator-type';
export default class DataItem {
    /**
     * 获取链接符
     * @param field
     */
    static getOperator(field) {
        let operator = [];
        // 系统数据项
        if (field.isSystemProperty) {
            // 内置的系统数据项
            const showSystemCodeItemType = Object.values(SystemCodeItemType);
            if (showSystemCodeItemType.includes(field.code)) {
                switch (field.code) {
                    case SystemCodeItemType.CreatedBy:
                    case SystemCodeItemType.Owner:
                        operator = [...EqualOrNotEqual, ...OfOrNotOf];
                        break;
                    case SystemCodeItemType.CreatedDept:
                    case SystemCodeItemType.OwnerDept:
                        operator = [...EqualOrNotEqual, ...ContainOrNotContain, ...OfOrNotOf];
                        break;
                    case SystemCodeItemType.CreatedTime:
                    case SystemCodeItemType.ModifiedTime:
                        operator = [
                            ...EqualOrNotEqual,
                            ...GreaterThanOrLessThan,
                            ...LessThanEqualOrGreaterThanEqual,
                        ];
                        break;
                    case SystemCodeItemType.Status:
                        operator = [...EqualOrNotEqual];
                        break;
                    case SystemCodeItemType.Document:
                        operator = [...EqualOrNotEqual, ...ContainOrNotContain];
                        break;
                }
            }
            else {
                // 扩展的系统数据项
                operator = this.getBusinessOperator(field);
            }
            return operator;
        }
        // 业务数据项
        operator = this.getBusinessOperator(field);
        return operator;
    }
    /**
     * 获取业务数据项的链接符
     * @param field
     * @returns
     */
    static getBusinessOperator(field = {}) {
        let operator = [];
        switch (field.valueType) {
            case BusinessDataItemType.ShortText:
            case BusinessDataItemType.LongText:
                operator = [
                    ...EqualOrNotEqual,
                    ...ContainOrNotContain,
                    ...NullOrNotNull,
                ];
                break;
            case BusinessDataItemType.Date:
                operator = [
                    ...EqualOrNotEqual,
                    ...GreaterThanOrLessThan,
                    ...LessThanEqualOrGreaterThanEqual,
                    ...NullOrNotNull,
                ];
                break;
            case BusinessDataItemType.Number:
            case BusinessDataItemType.Decimal:
                operator = [
                    ...EqualOrNotEqual,
                    ...GreaterThanOrLessThan,
                    ...LessThanEqualOrGreaterThanEqual,
                    ...NullOrNotNull,
                ];
                break;
            // 单选文本
            case BusinessDataItemType.RadioText:
                operator = [...EqualOrNotEqual, ...NullOrNotNull];
                break;
            // 多选文本
            case BusinessDataItemType.MultiSelectText:
                operator = [...OfOrNotOf, ...ContainOrNotContain, ...NullOrNotNull];
                break;
            // 逻辑
            case BusinessDataItemType.Boolean:
                operator = [...Equal];
                break;
            // 人员部门混选、人员多选、部门多选
            case BusinessDataItemType.PersonDepartmentSelect:
            case BusinessDataItemType.MultiSelectPerson:
            case BusinessDataItemType.MultiSelectDepartment:
                operator = [...ContainOrNotContain, ...OfOrNotOf];
                break;
            // 人员单选
            case BusinessDataItemType.RadioPerson:
                operator = [...EqualOrNotEqual, ...OfOrNotOf];
                break;
            // 部门单选
            case BusinessDataItemType.RadioDepartment:
                operator = [...EqualOrNotEqual, ...ContainOrNotContain, ...OfOrNotOf];
                break;
        }
        return operator;
    }
    /**
     * 获取默认值
     * @param field
     */
    static getDefaultValue(field) {
        let defaultValue = '';
        if (StringDefaultValue.includes(field.valueType)) {
            defaultValue = '';
        }
        if (UndefinedDefaultValue.includes(field.valueType)) {
            defaultValue = undefined;
        }
        if (ArrayDefaultValue.includes(field.valueType)) {
            defaultValue = [];
        }
        return defaultValue;
    }
    /**
     * 是否是输入框
     * @param field
     */
    static isInput(field) {
        const valueType = [ValueType.Input, ValueType.Textarea];
        return valueType.includes(field.valueType);
    }
    /**
     * 是否是整数输入框
     * @param field
     */
    static isInt(field) {
        const valueType = [ValueType.Number];
        return valueType.includes(field.valueType);
    }
    /**
     * 是否是小数输入框
     * @param field
     */
    static isDecimal(field) {
        const valueType = [ValueType.Decimal];
        return valueType.includes(field.valueType);
    }
    /**
     * 是否是日期输入框
     * @param field
     */
    static isDate(field) {
        const valueType = [ValueType.DatePicker];
        return valueType.includes(field.valueType) && field.format === 'yyyy-MM-dd';
    }
    /**
     * 是否是日期时间输入框
     * @param field
     */
    static isDateTime(field) {
        const valueType = [ValueType.DateTimePicker];
        return (valueType.includes(field.valueType) &&
            field.format === 'yyyy-MM-dd HH:mm:ss');
    }
    /**
     * 是否是时间输入框
     * @param field
     */
    static isTime(field) {
        const valueType = [ValueType.TimePicker];
        return valueType.includes(field.valueType) && field.format === 'HH:mm:ss';
    }
    /**
     * 是否是下拉框
     * @param field
     */
    static isSelect(field) {
        const valueType = [
            ValueType.Boolean,
            ValueType.Select,
            ValueType.MultiSelect,
        ];
        // 默认的
        if (valueType.includes(field.valueType)) {
            return true;
        }
        return false;
    }
    /**
     * 是否是选人控件
     * @param field
     */
    static isStaffSelector(field) {
        const valueType = [
            ValueType.StaffPicker,
            ValueType.StaffMultiPicker,
            ValueType.DeptPicker,
            ValueType.DeptMultiPicker,
            ValueType.StaffDeptPicker,
        ];
        return valueType.includes(field.valueType);
    }
    static isNullOrNotNull(value) {
        const hideComp = [
            DateItemOperatorType.IsNull,
            DateItemOperatorType.IsNotNull,
        ];
        return hideComp.includes(value);
    }
    static isNull(value) {
        const hideComp = [
            DateItemOperatorType.IsNull,
        ];
        return hideComp.includes(value);
    }
    /**
     * 获取下拉框数据
     * @param field
     */
    static getSelectData(field) {
        if (field.valueType === ValueType.Boolean) {
            return SelectList.BooleanList;
        }
        if (field.valueType === ValueType.Select ||
            field.valueType === ValueType.MultiSelect) {
            return field.selectData.map((item) => {
                return {
                    name: item.text,
                    value: item.key,
                };
            });
        }
        return [];
    }
    /**
     * 获取选人控件的配置
     * @param field
     */
    static getStaffSelectorOpts(field, defaultOperator) {
        const options = {
            selectDept: true,
            selectUser: true,
            showSelect: false,
        };
        const includeOperator = (operatorList, operator) => {
            return operatorList.some((item) => item.value === operator);
        };
        if (field.valueType === ValueType.StaffPicker &&
            includeOperator(EqualOrNotEqual, defaultOperator)) {
            // 等于不等于的人员单选
            options.multiple = false;
        }
        else if (field.valueType === ValueType.StaffMultiPicker &&
            includeOperator(ContainOrNotContain, defaultOperator)) {
            // 包含不包含的人员多选
            options.multiple = true;
        }
        else if (((field.valueType === ValueType.StaffPicker || field.valueType === ValueType.StaffMultiPicker) &&
            includeOperator(OfOrNotOf, defaultOperator))) {
            // 属于不属于的人员单选和人员多选
            options.multiple = true;
        }
        else if (field.valueType === ValueType.DeptPicker &&
            includeOperator(EqualOrNotEqual, defaultOperator)) {
            // 等于不于等的部门单选
            options.multiple = false;
        }
        else if (field.valueType === ValueType.DeptMultiPicker ||
            (field.valueType === ValueType.DeptPicker &&
                (includeOperator(OfOrNotOf, defaultOperator) || includeOperator(ContainOrNotContain, defaultOperator)))) {
            // 部门多选或属于不属于和包不包含的部门单选
            options.multiple = true;
        }
        else if (field.valueType === ValueType.StaffDeptPicker) {
            // 人员混选
            options.multiple = true;
        }
        return options;
    }
    /**
     * 获取moment对象的日期格式
     * @param format
     */
    static getMomentDateFormat(format = '') {
        let localFormat = '';
        const formatArr = format ? format.split(' ') : [];
        if (!formatArr.length)
            return localFormat;
        const prefixFormat = formatArr[0]
            .split('-')
            .map((item) => {
            return item.toUpperCase();
        })
            .join('-');
        localFormat = formatArr[1]
            ? `${prefixFormat} ${formatArr[1]}`
            : `${prefixFormat}`;
        return localFormat;
    }
    /**
     * 把日期的moment对象转换成string
     * @param date
     * @param format
     */
    static getDateString(date, format) {
        if (!date)
            return date;
        if (format === 'HH:mm:ss') {
            return moment(date).format(format);
        }
        return moment(date).format(this.getMomentDateFormat(format));
    }
    /**
     * 返回日期的Moment对象
     * @param value
     */
    static getMomentDate(value, format) {
        if (!value)
            return value;
        if (format === 'HH:mm:ss') {
            return moment(value, format);
        }
        return moment(value, this.getMomentDateFormat(format));
    }
}
