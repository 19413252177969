import { __decorate } from "tslib";
import { Component, Vue, Prop, Watch, } from 'vue-property-decorator';
import * as Utils from '@/common/utils';
import { NodeType } from '@/typings/app-auth';
import { EmptyPage } from '../common-comp';
// import { AppAuthService } from '@/sevices';
let MenuAuth = class MenuAuth extends Vue {
    constructor() {
        super(...arguments);
        this.isEdit = false;
        this.isInit = false;
        this.expandedKeys = [];
    }
    onExpand(expandedKeys, e) {
        const key = e.node.dataRef.key;
        if (this.expandedKeys.includes(key)) {
            this.expandedKeys.splice(this.expandedKeys.findIndex((item) => item === key), 1);
        }
        else {
            this.expandedKeys.push(key);
        }
    }
    /**
     * 选中树选择框触发的事件
     */
    onChangeMenuItemCheckbox(checkedKeys, e) {
        const checkedKey = e.node.dataRef.key;
        const rootMenu = this.getMenu(this.menuTree, checkedKey);
        rootMenu.checked = rootMenu.checkedKeys.length === rootMenu.nodeNumber;
        rootMenu.indeterminate =
            rootMenu.checkedKeys.length > 0 &&
                rootMenu.checkedKeys.length < rootMenu.nodeNumber;
    }
    /**
     * 根据菜单项获取菜单
     */
    getMenu(menuTree, key) {
        let rootMenu = {};
        let isEnd = false;
        const getMenu = (tree) => {
            for (let i = 0, len = tree.length; i < len; i++) {
                const item = tree[i];
                if (item.nodeType === NodeType.Menu) {
                    rootMenu = item;
                }
                if (item.key === key) {
                    isEnd = true;
                    break;
                }
                if (item.tree && item.tree.length) {
                    getMenu(item.tree);
                }
                if (item.children && item.children.length) {
                    getMenu(item.children);
                }
                if (isEnd) {
                    break;
                }
            }
        };
        getMenu(menuTree);
        return rootMenu;
    }
    /**
     * 是否全选菜单项
     */
    async onChangeCheckbox(menu) {
        await Utils.sleep(50);
        if (menu.checked) {
            menu.indeterminate = false;
            this.forEachTree(menu.tree, (item) => {
                if (!menu.checkedKeys.includes(item.key)) {
                    menu.checkedKeys.push(item.key);
                }
            });
        }
        else {
            this.forEachTree(menu.tree, (item) => {
                menu.checkedKeys.splice(menu.checkedKeys.findIndex((key) => key === item.key), 1);
            });
        }
    }
    /**
     * 遍历树
     */
    forEachTree(tree, callback = () => { }) {
        tree.forEach((item) => {
            callback(item);
            if (item.children && item.children.length) {
                this.forEachTree(item.children, callback);
            }
        });
    }
    getMenuKey() {
        const result = {};
        this.menuTree.forEach((item) => {
            if (item.checked || item.indeterminate) {
                result[item.code] = item.checkedKeys;
            }
        });
        return result;
    }
    onMenuTreeChange(menuTree = []) {
        const firstNode = menuTree[0];
        if (firstNode) {
            if (!this.activeKey.includes(firstNode.code)) {
                this.activeKey.push(firstNode.code);
            }
        }
    }
};
__decorate([
    Prop({ default: () => [] })
], MenuAuth.prototype, "menuTree", void 0);
__decorate([
    Prop({ default: () => [] })
], MenuAuth.prototype, "activeKey", void 0);
__decorate([
    Watch('menuTree', { immediate: true })
], MenuAuth.prototype, "onMenuTreeChange", null);
MenuAuth = __decorate([
    Component({
        name: 'MenuAuth',
        components: { EmptyPage },
    })
], MenuAuth);
export default MenuAuth;
