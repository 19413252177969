import { __decorate } from "tslib";
import { Component, Prop, Emit } from 'vue-property-decorator';
import AuthTableBase from '../../../common-comp/component-table/auth-table-base';
let FieldAuthTable = class FieldAuthTable extends AuthTableBase {
    constructor() {
        super(...arguments);
        this.compAuthColumns = [
            {
                title: '字段名称',
                dataIndex: 'name',
                key: 'name',
                width: 300,
                scopedSlots: { customRender: 'name' },
            },
            {
                dataIndex: 'isVisible',
                key: 'isVisible',
                slots: { title: 'visibleTitle' },
                align: 'right',
                scopedSlots: { customRender: 'isVisible' },
            },
            {
                dataIndex: 'isWritable',
                key: 'isWritable',
                slots: { title: 'writableTitle' },
                align: 'right',
                scopedSlots: { customRender: 'isWritable' },
            },
            {
                title: '操作',
                dataIndex: 'action',
                key: 'action',
                width: 100,
                slots: { title: 'action' },
                align: 'center',
                scopedSlots: { customRender: 'action' },
            },
        ];
    }
    get tableData() {
        return this.compData.map((item, index) => {
            return {
                index,
                ...item,
            };
        });
    }
    onAdvanceAuthChangeVisible() {
        super.onChangeVisible();
    }
    onAdvanceAuthChangeWritable() {
        super.onChangeWritable();
    }
    disabledVisible(record) {
        return super.disabledVisible(record);
    }
    disabledWritable(record) {
        return super.disabledWritable(record);
    }
    AdvanceAuthCheckVisible(item) {
        super.checkVisible(item);
    }
    AdvanceAuthCheckWritable(item) {
        super.checkWritable(item);
    }
    onSelectRuleField(record) {
        return record;
    }
    delAdvanceResult(item) {
        return item;
    }
    onChangeRuleField() { }
};
__decorate([
    Prop({ default: () => [] })
], FieldAuthTable.prototype, "ruleFields", void 0);
__decorate([
    Emit('onSelectRuleField')
], FieldAuthTable.prototype, "onSelectRuleField", null);
__decorate([
    Emit('delAdvanceResult')
], FieldAuthTable.prototype, "delAdvanceResult", null);
__decorate([
    Emit('onChangeRuleField')
], FieldAuthTable.prototype, "onChangeRuleField", null);
FieldAuthTable = __decorate([
    Component({
        name: 'FieldAuthTable',
    })
], FieldAuthTable);
export default FieldAuthTable;
