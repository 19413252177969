import { __decorate } from "tslib";
import { Component, Vue, Prop, Ref, } from 'vue-property-decorator';
let ComponentBase = class ComponentBase extends Vue {
    addWacthEvent(condition = {}) {
        if (condition.componetType.isStaffSelector) {
            if (condition.conditionValue.length) {
                condition.errorInfo.isError = false;
            }
            else {
                condition.errorInfo.isError = true;
            }
        }
        else if (condition.componetType.isSelect) {
            if (condition.conditionValue === undefined) {
                condition.errorInfo.isError = true;
            }
            else {
                condition.errorInfo.isError = false;
            }
        }
        else {
            if (!this.hasAttr(condition.conditionValue)) {
                condition.errorInfo.isError = true;
            }
            else {
                condition.errorInfo.isError = false;
            }
        }
    }
    async addBlurEvent(condition) {
        await this.$nextTick();
        const blurInput = this.component.querySelector('input') || {};
        blurInput.removeEventListener('blur', () => { });
        blurInput.addEventListener('blur', () => {
            if (condition.conditionValue) {
                condition.errorInfo.isError = false;
            }
            else {
                condition.errorInfo.isError = true;
            }
        });
    }
    hasAttr(obj) {
        for (const key in obj) {
            if (obj[key]) {
                return true;
            }
        }
        return false;
    }
};
__decorate([
    Prop()
], ComponentBase.prototype, "condition", void 0);
__decorate([
    Ref()
], ComponentBase.prototype, "component", void 0);
ComponentBase = __decorate([
    Component
], ComponentBase);
export default ComponentBase;
