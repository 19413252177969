import { __decorate } from "tslib";
import { Component, Vue, Prop, Watch, } from 'vue-property-decorator';
let CustomServiceAuth = class CustomServiceAuth extends Vue {
    constructor() {
        super(...arguments);
        this.columns = [
            {
                title: '服务',
                dataIndex: 'name',
                key: 'name',
                width: 400,
            },
            {
                dataIndex: 'isAuth',
                key: 'isAuth',
                align: 'right',
                slots: { title: 'isAuthTitle' },
                scopedSlots: { customRender: 'isAuth' },
            },
        ];
    }
    get filterBizServices() {
        // const res: any[] = [];
        // this.bizServices.forEach((item: any) => {
        //   if (!item.isModelServer || (item.isModelServer && item.applevel)) {
        //     res.push(item);
        //   }
        // });
        return this.bizServices;
    }
    // 是否全选可见
    get isCheckAllAuth() {
        let result = false;
        if (this.bizServices.length) {
            result = this.bizServices.every((item) => item.isAuth);
        }
        return result;
    }
    // 是否半选可见
    get indeterminateAuth() {
        let res = false;
        if (this.isCheckAllAuth) {
            res = false;
        }
        else {
            res = this.bizServices.some((item) => item.isAuth);
        }
        return res;
    }
    onDetailBizServicesChange() {
        this.addDetailFieldToServiceAuth();
    }
    addDetailFieldToServiceAuth() {
        this.bizServices.forEach((item) => {
            this.$set(item, 'isAuth', false);
            const detailService = this.detailBizServices.find((detailItem) => detailItem.serviceCode === item.code);
            if (detailService && detailService.serviceCode === item.code) {
                if ((detailService.perm & 1) === 1) {
                    item.isAuth = true;
                }
            }
        });
    }
    onChangeAuth(e) {
        const checked = e.target.checked;
        this.bizServices.forEach((item) => {
            item.isAuth = checked;
        });
    }
};
__decorate([
    Prop({ default: () => [] })
], CustomServiceAuth.prototype, "bizServices", void 0);
__decorate([
    Prop({ default: () => [] })
], CustomServiceAuth.prototype, "detailBizServices", void 0);
__decorate([
    Watch('detailBizServices', { immediate: true })
], CustomServiceAuth.prototype, "onDetailBizServicesChange", null);
CustomServiceAuth = __decorate([
    Component({
        name: 'CustomServiceAuth',
    })
], CustomServiceAuth);
export default CustomServiceAuth;
