var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"perm-staff-reselected-container"},[_c('div',{ref:"staffReselectedList",staticClass:"staff-reselected-list"},_vm._l((_vm.selectedNodes),function(item,index){return _c('div',{key:item.id,staticClass:"staff-reselected-item",on:{"dblclick":_vm.dblclick}},[_c('Avatar',{staticClass:"reseleted-value-avator",attrs:{"nodeType":item.nodeType,"imgUrl":item.imgUrl}}),_c('div',{staticClass:"reselected-value-text",class:{
          'integrated-text':
            item.relatedSyncType === 'PULL' && item.nodeType === _vm.NodeType.Org
        },attrs:{"title":item.namePath}},[_vm._v(" "+_vm._s(item.name)+" ")]),(
          item.relatedSyncType === 'PULL' && item.nodeType === _vm.NodeType.Org
        )?_c('div',{staticClass:"integrated-label",class:{
          'ding-talk': item.sourceType === _vm.SourceType.DingtalkMaintain
        }},[_vm._v(" "+_vm._s(item.sourceType === _vm.SourceType.DingtalkMaintain ? '钉钉' : '企微')+" ")]):_vm._e(),_c('span',{staticClass:"remove-staff",on:{"click":function($event){$event.stopPropagation();return _vm.removeNode(index)}}},[_c('a-icon',{attrs:{"type":"close"}})],1)],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }