import { __decorate } from "tslib";
import { Component } from 'vue-property-decorator';
import ComponentBase from './component-base';
let CTimePicker = class CTimePicker extends ComponentBase {
};
CTimePicker = __decorate([
    Component({
        name: 'CTimePicker',
    })
], CTimePicker);
export default CTimePicker;
