import { __decorate } from "tslib";
import { Component, Vue, Prop, Emit, } from 'vue-property-decorator';
import { Icon, Spin } from '@h3/antd-vue';
import StaffReselectedContainer from './staff-reselected-container.vue';
let StaffSelectorInput = class StaffSelectorInput extends Vue {
    clickInput() { }
};
__decorate([
    Prop()
], StaffSelectorInput.prototype, "selectedNodes", void 0);
__decorate([
    Prop({ default: true })
], StaffSelectorInput.prototype, "loading", void 0);
__decorate([
    Emit('clickEvent')
], StaffSelectorInput.prototype, "clickInput", null);
StaffSelectorInput = __decorate([
    Component({
        name: 'StaffSelectorInput',
        components: {
            StaffReselectedContainer,
            AIcon: Icon,
            ASpin: Spin,
        },
    })
], StaffSelectorInput);
export default StaffSelectorInput;
