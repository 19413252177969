/**
 * 逻辑的下拉列表
 */
export const BooleanList = [
    {
        name: '是',
        value: '1',
    },
    {
        name: '否',
        value: '0',
    },
];
/**
 * 选人控件的下拉列表
 */
export const StaffSelectorList = [
    {
        name: '本人',
        value: 'SELF_P',
    },
    {
        name: '本部门',
        value: 'SELF_D',
    },
];
