import { __decorate } from "tslib";
import { Component } from 'vue-property-decorator';
import ComponentBase from './component-base';
let CInputNumber = class CInputNumber extends ComponentBase {
};
CInputNumber = __decorate([
    Component({
        name: 'CInputNumber',
    })
], CInputNumber);
export default CInputNumber;
