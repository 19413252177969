import { __decorate } from "tslib";
import { Component, Vue, Prop, Watch, } from 'vue-property-decorator';
import { ComponentTable } from '../../../common-comp';
import AdvanceDataAuth from '../advance-data-auth/index.vue';
import CustomDataAuthSet from '../custom-data-set/index.vue';
import { AuthType, OperateAuthMapping } from '@/typings/app-auth';
import { namespace } from 'vuex-class';
const AppAuth = namespace('AppAuth');
let DomainModel = class DomainModel extends Vue {
    constructor() {
        super(...arguments);
        this.activeKey = [];
        this.dataAuthRadio = [
            {
                name: '全部可见',
                value: 1,
            },
            {
                name: '本组织',
                value: 10,
            },
            {
                name: '本组织及下级组织',
                value: 11,
            },
            {
                name: '本部门',
                value: 3,
            },
            {
                name: '本部门及下级部门',
                value: 6,
            },
            {
                name: '仅自己',
                value: 4,
            },
            {
                name: '自定义',
                value: 5,
            },
        ];
        this.isInit = true;
        this.operateAuthCheckbox = [...this.generateOperateAuthFields()];
    }
    get authType() {
        return Number(this.$route.query.authType);
    }
    onDomainModelCodeChange(domainModelCode) {
        this.isInit = true;
        const firstChild = this.bizSchemas[0];
        if (firstChild &&
            !this.expandedDomainModelCodes.includes(domainModelCode)) {
            this.activeKey.push(firstChild.code);
        }
        if (!this.expandedDomainModelCodes.includes(domainModelCode)) {
            this.expandedDomainModelCodes.push(domainModelCode);
        }
    }
    created() {
        this.addRoleDataAuth();
    }
    onChangeCollapse(key) {
        this.isInit = false;
        this.activeKey = key;
    }
    generateOperateAuthFields() {
        const operateAuthCheckbox = [];
        const serviceList = Object.keys(OperateAuthMapping);
        for (const serviceKey of serviceList) {
            const serviceName = OperateAuthMapping[serviceKey];
            operateAuthCheckbox.push({
                serviceName: serviceName,
                triggerType: serviceKey,
            });
        }
        return operateAuthCheckbox;
    }
    /**
     * 复制一份数据
     */
    getCopyData(data = []) {
        let result = [];
        try {
            result = JSON.parse(JSON.stringify(data));
        }
        catch (e) {
            Vue.prototype.$errorcatcher.showMessagePopup({
                title: '错误提示！',
                message: [e, '!'],
                displayFooter: false,
                correctGuide: '请检查元数据是否正确',
            });
        }
        return result;
    }
    /**
     * 提交的高级权限的数据
     */
    confirmAdvanceAuthData(data = {}) {
        const schemasCode = data.schemasCode;
        const conditionDataAuth = data.conditionDataAuth || {};
        const advanceResultData = data.advanceResultData || [];
        const operateType = data.operateType;
        const curOperateIndex = data.curOperateIndex;
        const bizSchema = this.bizSchemas.find((model) => model.code === schemasCode);
        const advanceAuthData = bizSchema.serviceData.advanceAuthData;
        const newAdvanceAuthData = {
            conditionDataAuth,
            advanceResultData,
        };
        if (operateType === 0) {
            // 新增
            this.$set(advanceAuthData, advanceAuthData.length, newAdvanceAuthData);
        }
        else if (operateType === 1) {
            // 编辑
            this.$set(advanceAuthData, curOperateIndex, newAdvanceAuthData);
        }
    }
    /**
     * 删除，排序会更新的高级权限数据
     */
    updateAdvanceAuthData(data) {
        if (!data)
            return;
        const schemasCode = data.schemasCode;
        const advanceAuthData = data.advanceAuthData;
        const bizSchema = this.bizSchemas.find((model) => model.code === schemasCode);
        bizSchema.serviceData.advanceAuthData = advanceAuthData;
    }
    addRoleDataAuth() {
        if (this.authType === AuthType.Role) {
            this.dataAuthRadio.splice(1, 0, {
                name: '标签管理范围',
                value: 2,
            });
        }
    }
};
__decorate([
    AppAuth.State('path')
], DomainModel.prototype, "path", void 0);
__decorate([
    AppAuth.State('expandedDomainModelCodes')
], DomainModel.prototype, "expandedDomainModelCodes", void 0);
__decorate([
    Prop({ default: '' })
], DomainModel.prototype, "domainModelCode", void 0);
__decorate([
    Prop({ default: () => [] })
], DomainModel.prototype, "bizSchemas", void 0);
__decorate([
    Watch('domainModelCode', { immediate: true })
], DomainModel.prototype, "onDomainModelCodeChange", null);
DomainModel = __decorate([
    Component({
        name: 'DomainModel',
        components: {
            ComponentTable,
            CustomDataAuthSet,
            AdvanceDataAuth,
        },
    })
], DomainModel);
export default DomainModel;
