import { __decorate } from "tslib";
import { Component, Ref } from 'vue-property-decorator';
import ComponentBase from './component-base';
// eslint-disable-next-line max-len
import { BusinessDataItemType } from '@/components/app-manage/auth-config-set/common-comp/data-auth/data-item-condition-type';
let CSelect = class CSelect extends ComponentBase {
    getMode(condition) {
        return condition.valueType === BusinessDataItemType.MultiSelectText
            ? 'multiple'
            : 'default';
    }
    created() {
        this.readonlySelect();
    }
    /**
     * 设置组件只能读，不能搜索
     */
    readonlySelect() {
        this.$nextTick(() => {
            if (!this.component)
                return;
            const input = this.select.$el.querySelector('.ant-select-search__field');
            if (!input)
                return;
            input.setAttribute('readonly', '');
        });
    }
};
__decorate([
    Ref()
], CSelect.prototype, "select", void 0);
CSelect = __decorate([
    Component({
        name: 'CSelect',
    })
], CSelect);
export default CSelect;
