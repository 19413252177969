import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { UnitType, NodeType, SourceType } from '@/typings/organization/org-manage';
import { OrgManageService } from '@/sevices';
let CStaffSelectorBase = class CStaffSelectorBase extends Vue {
    constructor() {
        super(...arguments);
        this.inputLoading = true;
    }
    /**
   * 获取ids
   */
    getIds(loadValue, type) {
        const ids = loadValue
            .filter((value) => {
            return value.type === type;
        })
            .map((value) => {
            return value.id;
        })
            .join(',');
        return ids;
    }
    /**
   * 根据ids获取用户列表
   * @param ids
   * @returns
   */
    getUsers(ids) {
        return new Promise((resolve, reject) => {
            OrgManageService.getUsers({
                ids: ids,
            })
                .then((res) => {
                if (res.success) {
                    resolve(res.data);
                }
            })
                .catch((e) => {
                reject(e);
            });
        });
    }
    /**
   * 根据ids获取部门列表
   * @param ids
   * @returns
   */
    getDepts(ids) {
        return new Promise((resolve, reject) => {
            OrgManageService.getDepts({
                ids: ids,
            })
                .then((res) => {
                if (res.success) {
                    resolve(res.data);
                }
            })
                .catch((e) => {
                reject(e);
            });
        });
    }
    /**
   * 遍历回选值
   */
    forEachList(list = []) {
        const selectedNodes = [];
        list.forEach((item) => {
            const isUser = !!item.baseUserId;
            const selectedNode = this.generateSelectedNode(isUser, item);
            selectedNodes.push(selectedNode);
        });
        return selectedNodes;
    }
    /**
   * 获取已选的部门和用户列表
   */
    getOrgInfo(loadValue) {
        const deptIds = this.getIds(loadValue, UnitType.Dept);
        const userIds = this.getIds(loadValue, UnitType.User);
        const loadPromises = [];
        if (deptIds.length) {
            const getDepts = this.getDepts(deptIds);
            loadPromises.push(getDepts);
        }
        if (userIds.length) {
            const getUsers = this.getUsers(userIds);
            loadPromises.push(getUsers);
        }
        const promises = Promise.all(loadPromises);
        return new Promise((resolve, reject) => {
            promises
                .then((res) => {
                const res0 = res[0];
                const res1 = res[1];
                let arr = [];
                if (res0) {
                    arr = [...res0];
                }
                if (res1) {
                    arr = [...arr, ...res1];
                }
                const result = this.forEachList(arr);
                resolve(result);
            })
                .catch((e) => {
                reject(e);
            });
        });
    }
    /**
   * 生成选中的节点
   * @param item
   * @returns
   */
    generateSelectedNode(isUser, item) {
        const idPathField = isUser
            ? 'mainDepartmentFullIdPath'
            : 'parentDepartmentIds';
        const idPath = item[idPathField]?.split('/') || [];
        // 部门人员路径，接口返回的部门用parentDepartmentIds，人员用mainDepartmentFullIdPath
        const namePath = isUser
            ? item.mainDepartmentFullNamePath?.split('/') || []
            : item.parentDepartmentNames?.split('/') || [];
        // 接口返回的部门用parentId，人员用mainDepartmentId
        const parentId = isUser ? item.mainDepartmentId : item.parentId;
        const node = {
            id: item.id,
            name: item.name,
            nodeType: isUser
                ? NodeType.User
                : item.isRoot
                    ? NodeType.Org
                    : NodeType.Dept,
            relatedSyncType: item.sourceType === SourceType.SelfMaintain ? 'PUSH' : 'PULL',
            sourceType: item.sourceType,
            parentId: parentId,
            namePath: isUser
                ? [...namePath, item.name].join('/')
                : namePath.join('/'),
            imgUrl: item.imgUrl,
            orgId: item.orgId,
            idPath: isUser ? [...idPath, item.id] : idPath,
            source: item,
        };
        if (!isUser) {
            node.allChildrenTotal = item.childrenTotal;
        }
        return node;
    }
};
CStaffSelectorBase = __decorate([
    Component
], CStaffSelectorBase);
export default CStaffSelectorBase;
