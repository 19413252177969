export var DateItemOperatorType;
(function (DateItemOperatorType) {
    // 等于
    DateItemOperatorType["Equal"] = "1";
    // 不等于
    DateItemOperatorType["NotEqual"] = "2";
    // 大于
    DateItemOperatorType["GreaterThan"] = "3";
    // 小于
    DateItemOperatorType["LessThan"] = "4";
    // 大于等于
    DateItemOperatorType["GreaterThanOrEqual"] = "5";
    // 小于等于
    DateItemOperatorType["LessThanOrEqual"] = "6";
    // 包含
    DateItemOperatorType["Contains"] = "7";
    // 不包含
    DateItemOperatorType["NotContains"] = "8";
    // 为空
    DateItemOperatorType["IsNull"] = "9";
    // 不为空
    DateItemOperatorType["IsNotNull"] = "10";
    // 属于
    DateItemOperatorType["Of"] = "11";
    // 不属于
    DateItemOperatorType["NotOf"] = "12";
})(DateItemOperatorType || (DateItemOperatorType = {}));
export const Equal = [
    {
        label: '等于',
        value: DateItemOperatorType.Equal,
    },
];
/**
 * 等于或不等于
 */
export const EqualOrNotEqual = [
    {
        label: '等于',
        value: DateItemOperatorType.Equal,
    },
    {
        label: '不等于',
        value: DateItemOperatorType.NotEqual,
    },
];
/**
 * 小于等于或大于等于
 */
export const LessThanEqualOrGreaterThanEqual = [
    {
        label: '小于等于',
        value: DateItemOperatorType.LessThanOrEqual,
    },
    {
        label: '大于等于',
        value: DateItemOperatorType.GreaterThanOrEqual,
    },
];
/**
 * 大于或小于
 */
export const GreaterThanOrLessThan = [
    {
        label: '大于',
        value: DateItemOperatorType.GreaterThan,
    },
    {
        label: '小于',
        value: DateItemOperatorType.LessThan,
    },
];
/**
 * 为空或不为空
 */
export const NullOrNotNull = [
    {
        label: '为空',
        value: DateItemOperatorType.IsNull,
    },
    {
        label: '不为空',
        value: DateItemOperatorType.IsNotNull,
    },
];
/**
 * 包含或不包含
 */
export const ContainOrNotContain = [
    {
        label: '包含',
        value: DateItemOperatorType.Contains,
    },
    {
        label: '不包含',
        value: DateItemOperatorType.NotContains,
    },
];
/**
 * 属于或不属于
 */
export const OfOrNotOf = [
    {
        label: '属于',
        value: DateItemOperatorType.Of,
    },
    {
        label: '不属于',
        value: DateItemOperatorType.NotOf,
    },
];
