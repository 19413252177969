import { __decorate } from "tslib";
import { Component, Ref } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Loading } from '@cloudpivot-hermes/common-components';
import { AuthSide, MenuAuth, Module, } from '@/components/app-manage/auth-config-set/index';
import { AppAuthService } from '@/sevices';
import AppDataDetail from './app-data-detail';
import DataItem from '@/components/app-manage/auth-config-set/common-comp/data-auth/data-item';
// eslint-disable-next-line max-len
import { DateItemOperatorType } from '@/components/app-manage/auth-config-set/common-comp/data-auth/data-item-operator-type';
import { ValueType } from '@/components/app-manage/auth-config-set/common-comp/data-auth/data-item-value-type';
import { TreeNodeType } from '@/typings/app-auth';
import * as Utils from '@/common/utils';
import startValidate from './validate';
const AppAuth = namespace('AppAuth');
let AuthConfigSet = class AuthConfigSet extends AppDataDetail {
    constructor() {
        super(...arguments);
        this.pageLoading = false;
        this.submitLoading = false;
        this.treeNode = [];
        this.groupType = TreeNodeType.Group;
        this.menuAuthType = TreeNodeType.MenuAuth;
        // 监听分组切换
        this.groupCode = '';
    }
    get appCode() {
        return this.$route.params.appCode;
    }
    get permGroupId() {
        return this.$route.query.permGroupId;
    }
    get authName() {
        return this.$route.query.authName;
    }
    created() {
        this.getAppAuthData();
    }
    beforeDestroy() {
        this.setPath({});
        this.setExpandedDomainModelCodes([]);
    }
    selectNode(node = {}) {
        this.treeNode = node;
        if (node.nodeType === TreeNodeType.Group) {
            this.groupCode = node.code;
        }
        else {
            this.groupCode = '';
        }
    }
    /**
     * 获取提交到服务器的数据
     */
    getServiceData() {
        const serviceData = {};
        this.treeData.forEach((treeNode) => {
            if (treeNode.nodeType === TreeNodeType.MenuAuth) {
                const menuServiceData = this.getMenuServiceData(treeNode.menuTree);
                serviceData.menuServiceData = menuServiceData;
            }
            else if (treeNode.nodeType === TreeNodeType.GroupAuth) {
                const groupList = treeNode.children || [];
                const groupServiceData = this.getGroupServiceData(groupList);
                serviceData.groupServiceData = groupServiceData;
            }
        });
        return serviceData;
    }
    /**
     * 获取提交到服务器的菜单数据
     */
    getMenuServiceData(menuTree = []) {
        const menuServiceData = {};
        menuTree.forEach((item) => {
            if (item.checked || item.indeterminate) {
                const immediateKeys = [];
                super.forEachMenuTree(item.tree, (treeItem) => {
                    if (!item.checkedKeys.includes(treeItem.code)) {
                        const isImmediate = treeItem.children.some(child => item.checkedKeys.includes(child.code));
                        if (isImmediate) {
                            immediateKeys.push(treeItem.code);
                        }
                    }
                });
                menuServiceData[item.code] = [...immediateKeys, ...item.checkedKeys];
            }
        });
        return menuServiceData;
    }
    /**
     * 获取所有待提交服务器的应用权限数据
     */
    getGroupServiceData(groupList = []) {
        const groupData = {
            domainModelAuthData: {},
            pageData: [],
        };
        if (!Array.isArray(groupList))
            return groupData;
        let allDomainModels = [];
        for (const group of groupList) {
            const moduleTree = group.moduleTree || [];
            // 当前分组未处理的页面/
            const pages = moduleTree[1].children || [];
            // 获取经过处理的当前分组页面数据
            const pageServiceData = this.getPagesServiceData(pages);
            // 添加到服务器的当前分组页面权限数据
            const pageData = {
                appCode: this.appCode,
                permGroupId: this.permGroupId,
                // 分组Code
                groupCode: group.code,
                pageGroupList: pageServiceData,
            };
            // 编辑，添加页面限的ID
            if (group.pageAuthPermId) {
                pageData.permId = group.pageAuthPermId;
            }
            groupData.pageData.push(pageData);
            // 所有未处理的领域模型
            const domainModels = moduleTree[0].children || [];
            allDomainModels = [...allDomainModels, ...domainModels];
        }
        // 获取所有领域模型提交到服务器
        groupData.domainModelAuthData = this.getDomainModelServiceData(allDomainModels);
        return groupData;
    }
    getPagesServiceData(pages = []) {
        const result = [];
        pages.forEach((page) => {
            if (page.isCheckedPage) {
                const data = {
                    pageGroupCode: page.pageGroup || '',
                    pageMap: {},
                };
                data.pageMap[page.code] = [];
                if (Array.isArray(page.components)) {
                    page.components.forEach((item) => {
                        const comp = {
                            componentCode: item.code,
                        };
                        let perm = 0;
                        const componentAuthCheckbox = item.componentAuthCheckbox;
                        const authCheckboxKeys = Object.keys(componentAuthCheckbox);
                        authCheckboxKeys.forEach((key) => {
                            const authCheckbox = componentAuthCheckbox[key];
                            if (authCheckbox.checked) {
                                perm += authCheckbox.value;
                            }
                        });
                        comp.perm = perm;
                        data.pageMap[page.code].push(comp);
                    });
                }
                result.push(data);
            }
        });
        return result;
    }
    /**
     * 获取所有需要提交到服务器领域模型的数据
     */
    getDomainModelServiceData(domainModels = []) {
        const domainModelAuthData = [];
        domainModels.forEach((domainModel) => {
            // 领域模型
            const bizSchemas = domainModel.bizSchemas || [];
            const modelDataDtoMap = this.getBizSchemaServiceData(bizSchemas);
            const domainModelServiceData = {
                domainCode: domainModel.code,
                modelDataDtoMap: modelDataDtoMap,
            };
            if (domainModel.permDomainId) {
                domainModelServiceData.permDomainId = domainModel.permDomainId;
            }
            domainModelAuthData.push(domainModelServiceData);
        });
        return domainModelAuthData;
    }
    /**
     * 获取所有需要提交到服务器的模型数据
     */
    getBizSchemaServiceData(bizSchemas) {
        const modelDataDtoMap = {};
        bizSchemas.forEach((schemas) => {
            modelDataDtoMap[schemas.code] = {
                modelDataRowDto: {},
                modelDataColDtoList: [],
                modelDataOperatDtoList: [],
                modelDataHighDtoList: [],
            };
            const schemaData = modelDataDtoMap[schemas.code];
            const serviceData = schemas.serviceData;
            // 字段权限（列权限）
            schemaData.modelDataColDtoList = this.getFieldAuthServiceData(schemas.properties, 'field');
            // 操作权限
            schemaData.modelDataOperatDtoList = this.getOperateAuthServiceData(serviceData.operateAuth);
            // 数据权限（行权限）
            schemaData.modelDataRowDto = this.getDataAuthServiceData(serviceData.dataAuth, serviceData.customAuthData);
            // 高级权限
            schemaData.modelDataHighDtoList = this.getAdvanceAuthServiceData(serviceData.advanceAuthData);
        });
        return modelDataDtoMap;
    }
    /**
     * 获取提交到服务器的高级权限
     */
    getAdvanceAuthServiceData(advanceAuthData = []) {
        const modelDataHighDtoList = [];
        advanceAuthData.forEach((item) => {
            const advanceResultData = item.advanceResultData || [];
            const conditionDataAuth = item.conditionDataAuth || {};
            const advanceAuthDataItem = {
                condition: conditionDataAuth.condition,
                dataRowDtos: this.getConditionDataAuthServiceData(conditionDataAuth.selectedConditionList),
                modelDataColDtoList: this.getAdvanceResultServiceData(advanceResultData),
            };
            modelDataHighDtoList.push(advanceAuthDataItem);
        });
        return modelDataHighDtoList;
    }
    /**
     * 获取提交到服务器的高级权限规则结果
     */
    getAdvanceResultServiceData(advanceResultData = []) {
        return this.getFieldAuthServiceData(advanceResultData, 'advance');
    }
    /**
     * 获取提交到服务器的自定义数据权限
     */
    getDataAuthServiceData(dataAuth, customAuthData = {}) {
        const modelDataRowDto = {};
        modelDataRowDto.rowType = dataAuth;
        // 自定义权限
        if (dataAuth === 5) {
            modelDataRowDto.condition = customAuthData.condition;
            modelDataRowDto.dataRowDtos = this.getConditionDataAuthServiceData(customAuthData.selectedConditionList);
        }
        return modelDataRowDto;
    }
    /**
     * 获取提交到服务器的条件数据权限
     */
    getConditionDataAuthServiceData(selectedConditionList = []) {
        const dataRowDtos = [];
        for (const item of selectedConditionList) {
            let conditionValue = item.conditionValue;
            const staffSelectorList = [];
            const staffSelectorIdList = [];
            // 如果是日期和时间类型
            if (DataItem.isDate(item) ||
                DataItem.isTime(item) ||
                DataItem.isDateTime(item)) {
                conditionValue = DataItem.getDateString(item.conditionValue, item.format);
                // 后端要求纯日期需要补时间
                if (DataItem.isDate(item)) {
                    conditionValue = `${conditionValue} 16:00:00`;
                }
                // 后端要求纯时间需要补日期
                if (DataItem.isTime(item)) {
                    conditionValue = `1980-01-01 ${conditionValue}`;
                }
            }
            if (DataItem.isSelect(item) && Array.isArray(conditionValue)) {
                conditionValue = conditionValue.join(',');
            }
            // 如果是选人控件类型
            if (DataItem.isStaffSelector(item)) {
                conditionValue.forEach((staff) => {
                    staffSelectorList.push({
                        id: staff.id,
                        name: staff.name,
                        type: staff.type,
                    });
                    staffSelectorIdList.push(staff.id);
                });
                conditionValue = staffSelectorIdList.join(',');
            }
            if (DataItem.isNull(item.conditionOperatorValue)) {
                conditionValue = null;
            }
            const staffValue = {
                colName: item.code,
                logicOperta: item.conditionOperatorValue,
                value: conditionValue,
                subjectList: staffSelectorList,
            };
            if (!item.isSystemProperty) {
                staffValue.colType = item.valueType;
            }
            dataRowDtos.push(staffValue);
        }
        return dataRowDtos;
    }
    /**
     * 获取提交到服务器的操作权限
     */
    getOperateAuthServiceData(operateAuth = []) {
        return operateAuth.map((triggerType) => {
            return {
                triggerType: triggerType,
            };
        });
    }
    /**
     * 获取提交到服务器的字段权限（列权限）
     */
    getFieldAuthServiceData(fields = [], type = 'field') {
        const result = [];
        fields.forEach((field) => {
            const modelDataColDto = {
                colName: field.code,
            };
            const fieldAuthCheckbox = field.fieldAuthCheckbox;
            const authCheckboxKeys = Object.keys(fieldAuthCheckbox);
            let perm = 0;
            for (const checkboxKey of authCheckboxKeys) {
                const checkbox = fieldAuthCheckbox[checkboxKey];
                if (checkbox.checked) {
                    perm += checkbox.value;
                }
                modelDataColDto.perm = perm;
            }
            if (type === 'field' && perm) {
                result.push(modelDataColDto);
            }
            else if (type === 'advance') {
                result.push(modelDataColDto);
            }
        });
        return result;
    }
    /**
     * 获取所有分组code
     */
    getGroupCodeList() {
        const groupList = this.treeData[1].children || [];
        const groupCodeList = [];
        groupList.forEach((group) => {
            groupCodeList.push(group.code);
        });
        return groupCodeList;
    }
    /**
     * 验证自定义数据
     */
    validateCustomConditionData() {
        const isValid = this.treeData.every((root) => {
            if (!root.children.length)
                return true;
            const isRootValid = root.children.every((group) => {
                const moduleTree = group.moduleTree[0];
                const domainModels = moduleTree.children;
                if (!domainModels.length)
                    return true;
                const isDomainModelValid = domainModels.every((domainModel) => {
                    if (!domainModel.bizSchemas.length)
                        return true;
                    const isSchemasValid = domainModel.bizSchemas.every((schemas) => {
                        const serviceData = schemas.serviceData;
                        if (serviceData.dataAuth !== 5)
                            return true;
                        const selectedConditionList = serviceData.customAuthData.selectedConditionList;
                        // 自定义权限必须要添加一个条件
                        if (!selectedConditionList.length)
                            return false;
                        const isConditionValid = selectedConditionList.every((condition) => {
                            return !condition.errorInfo.isError;
                        });
                        return isConditionValid;
                    });
                    return isSchemasValid;
                });
                return isDomainModelValid;
            });
            return isRootValid;
        });
        return isValid;
    }
    /**
     * 展示自定义数据权限的错误信息
     */
    showCustomConditionErrorMes() {
        let groupErr = [];
        let moduleTreeErr = [];
        let domainModelErr = [];
        let schemasErr = [];
        let conditionErr = [];
        this.treeData.forEach((root) => {
            root.children.forEach((group) => {
                const moduleTree = group.moduleTree[0];
                const domainModels = moduleTree.children;
                domainModels.forEach((domainModel) => {
                    domainModel.bizSchemas.forEach((schemas) => {
                        const serviceData = schemas.serviceData;
                        const selectedConditionList = serviceData.customAuthData.selectedConditionList;
                        selectedConditionList.forEach((condition) => {
                            if (condition.componetType.isStaffSelector) {
                                condition.errorInfo.isError = startValidate(condition, 'hasNotLength');
                            }
                            else if (condition.componetType.isSelect) {
                                if (condition.valueType === ValueType.MultiSelect) {
                                    condition.errorInfo.isError = startValidate(condition, 'hasNotLength');
                                }
                                else {
                                    condition.errorInfo.isError = startValidate(condition, 'isUndefined');
                                }
                            }
                            else if (condition.conditionOperatorValue ===
                                DateItemOperatorType.IsNull ||
                                condition.conditionOperatorValue ===
                                    DateItemOperatorType.IsNotNull) {
                                condition.errorInfo.isError = false;
                            }
                            else {
                                condition.errorInfo.isError = startValidate(condition, 'isNotNull');
                            }
                            if (serviceData.dataAuth !== 5) {
                                condition.errorInfo.isError = false;
                            }
                            // 把条件的错误信息添加到条件数组
                            conditionErr.push(condition.errorInfo.isError);
                        });
                        schemas.isError = conditionErr.some((err) => err);
                        // 自定义权限必须要添加一个条件
                        if (serviceData.dataAuth === 5 && !selectedConditionList.length) {
                            schemas.isError = true;
                        }
                        conditionErr = [];
                        // 把模型的错误信息添加到模型数组
                        schemasErr.push(schemas.isError);
                    });
                    domainModel.isError = schemasErr.some((err) => err);
                    schemasErr = [];
                    domainModelErr.push(domainModel.isError);
                });
                moduleTree.isError = domainModelErr.some((err) => err);
                domainModelErr = [];
                moduleTreeErr.push(moduleTree.isError);
                group.isError = moduleTreeErr.some((err) => err);
                moduleTreeErr = [];
                groupErr.push(group.isError);
            });
            root.isError = groupErr.some((err) => err);
            groupErr = [];
        });
    }
    saveAuth() {
        this.showCustomConditionErrorMes();
        const isValid = this.validateCustomConditionData();
        if (!isValid)
            return;
        this.submitLoading = true;
        const serviceData = this.getServiceData();
        const menuServiceData = serviceData.menuServiceData;
        const groupServiceData = serviceData.groupServiceData;
        // 领域模型参数
        const permDomainDataServiceDtoListParams = {
            appCode: this.appCode,
            permGroupId: this.permGroupId,
            permDomainDataServiceDtoList: groupServiceData.domainModelAuthData,
        };
        const menuBarItemsMap = menuServiceData;
        // 菜单权限参数
        const permFeatureMenuDtoParams = {
            appCode: this.appCode,
            permGroupId: this.permGroupId,
            menuBarItemsMap: menuBarItemsMap,
        };
        // 编辑菜单
        if (this.menuDetail.permId) {
            permFeatureMenuDtoParams.permId = this.menuDetail.permId;
        }
        // 页面权限参数
        const permFeaturePageDtoParams = groupServiceData.pageData;
        const serviceParams = {
            permDomainDto: permDomainDataServiceDtoListParams,
            permFeatureMenuDto: permFeatureMenuDtoParams,
            permFeaturePageDto: permFeaturePageDtoParams,
        };
        AppAuthService.addAppAuthData(serviceParams)
            .then(async (res) => {
            if (res.success) {
                this.$message.success('保存成功');
                await this.getAppAuthDetailData();
                super.setDetailDataToTreeData();
            }
        })
            .finally(async () => {
            await Utils.sleep(100);
            this.submitLoading = false;
        });
    }
    /**
     * 重置应用权限数据
     */
    resetAppAuthData() {
        this.resetAppData();
        if (this.isEdit) {
            super.setDetailDataToTreeData();
        }
    }
    /**
     * 重置数据
     */
    resetAppData() {
        // 重置数据
        this.treeData.forEach((treeNode) => {
            if (treeNode.nodeType === TreeNodeType.MenuAuth) {
                // 重置菜单数据
                this.resetMenu(treeNode.menuTree);
            }
            else if (treeNode.nodeType === TreeNodeType.GroupAuth) {
                // 重置分组数据
                const groupList = treeNode.children || [];
                treeNode.isError = false;
                groupList.forEach((group) => {
                    const domainModelTreeData = group.moduleTree[0];
                    const pageTreeData = group.moduleTree[1];
                    group.isError = false;
                    this.resetDomainModel(domainModelTreeData);
                    this.resetPage(pageTreeData);
                });
            }
        });
    }
    /**
     * 重置菜单数据
     */
    resetMenu(menuTree = []) {
        super.forEachMenuTree(menuTree, (item) => {
            item.checked = false;
            item.indeterminate = false;
            item.checkedKeys = [];
        });
    }
    /**
     * 重置页面数据
     */
    resetPage(pageTreeData = {}) {
        const pages = pageTreeData.children || [];
        for (const page of pages) {
            page.isCheckedPage = false;
            const components = page.components || [];
            for (const comp of components) {
                const componentAuthCheckbox = comp.componentAuthCheckbox;
                for (const key in componentAuthCheckbox) {
                    const authCheckbox = componentAuthCheckbox[key];
                    authCheckbox.checked = false;
                }
            }
        }
    }
    /**
     * 重置领域模型数据
     */
    resetDomainModel(domainModelTreeData) {
        const domainModels = domainModelTreeData.children;
        domainModelTreeData.isError = false;
        domainModels.forEach((domainModel) => {
            domainModel.isError = false;
            domainModel.bizSchemas.forEach((schemas) => {
                const serviceData = schemas.serviceData;
                schemas.isError = false;
                // 自定义数据权限条件
                serviceData.customAuthData = {
                    condition: 'and',
                    selectedConditionList: [],
                };
                // 高级权限
                serviceData.advanceAuthData = [];
                // 数据权限
                serviceData.dataAuth = 0;
                // 操作权限
                serviceData.operateAuth = [];
                schemas.properties = schemas.properties.map((item) => {
                    const fieldAuthCheckbox = super.getAuthCheckbox(item);
                    return {
                        ...item,
                        fieldAuthCheckbox,
                    };
                });
            });
        });
    }
    async getApp() {
        const params = {
            appCode: this.appCode,
        };
        const res = await AppAuthService.getRuntimeApp(params);
        if (!res.success)
            return;
        this.appMetaData = res.data || {};
    }
    async getAppAuthDetailData() {
        const groupCodes = this.getGroupCodeList();
        const params = {
            appCode: this.appCode,
            groupCodes: groupCodes,
            permGroupId: this.permGroupId,
        };
        const res = await AppAuthService.getAppAuthDetailData(params);
        if (!res.success)
            return;
        this.appAuthDetailData = res.data || {};
    }
    async getAppAuthData() {
        try {
            this.pageLoading = true;
            await this.getApp();
            super.onDataChange();
            await this.getAppAuthDetailData();
            super.setDetailDataToTreeData();
        }
        finally {
            this.pageLoading = false;
        }
    }
};
__decorate([
    AppAuth.Mutation('setPath')
], AuthConfigSet.prototype, "setPath", void 0);
__decorate([
    AppAuth.Mutation('setExpandedDomainModelCodes')
], AuthConfigSet.prototype, "setExpandedDomainModelCodes", void 0);
__decorate([
    Ref()
], AuthConfigSet.prototype, "menuAuth", void 0);
AuthConfigSet = __decorate([
    Component({
        name: 'AuthConfigSet',
        components: {
            AuthSide,
            MenuAuth,
            Module,
            Loading,
        },
    })
], AuthConfigSet);
export default AuthConfigSet;
