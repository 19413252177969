const validate = {
    hasNotLength(condition = {}) {
        let res = false;
        if (condition.conditionValue?.length) {
            res = false;
        }
        else {
            res = true;
        }
        return res;
    },
    isUndefined(condition = {}) {
        let res = false;
        if (condition.conditionValue === undefined) {
            res = true;
        }
        else {
            res = false;
        }
        return res;
    },
    isNotNull(condition = {}) {
        let res = false;
        if (condition.conditionValue) {
            res = false;
        }
        else {
            res = true;
        }
        return res;
    },
};
const startValid = (condition, hasLength) => {
    return validate[hasLength](condition);
};
export default startValid;
