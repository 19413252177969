import { Vue } from 'vue-property-decorator';
import { NodeType } from '@/typings/app-auth';
export default class AppDataMenu extends Vue {
    constructor() {
        super(...arguments);
        this.menuActiveKey = [];
    }
    /**
     * 生成菜单
     */
    generateRootMenuTree(menus = []) {
        const tree = [];
        let children = [];
        let node = {};
        if (!Array.isArray(menus) || !menus.length)
            return tree;
        menus.forEach((item) => {
            if (item.appMenuItems) {
                children = this.generateMenuItemTree(item.appMenuItems, item.menuItemCode);
            }
            node = {
                name: item.menuName,
                nodeType: NodeType.Menu,
                checkedKeys: [],
                checked: false,
                nodeNumber: this.calcNodeNumber(children),
                indeterminate: false,
                key: item.menuCode,
                code: item.menuCode,
                tree: children,
            };
            tree.push(node);
        });
        return tree;
    }
    /**
     * 计算菜单拥有菜单项的个数
     */
    calcNodeNumber(children) {
        let number = 0;
        this.forEachMenuTree(children, () => {
            number++;
        });
        return number;
    }
    /**
     * 生成菜单项
     */
    generateMenuItemTree(dataTree, parentCode = '') {
        let tree = [];
        let node = {};
        let children = [];
        if (!Array.isArray(dataTree) || !dataTree.length)
            return tree;
        for (let i = 0, len = dataTree.length; i < len; i++) {
            const item = dataTree[i];
            if (item.parentCode === parentCode) {
                node = {
                    title: item.menuItemName,
                    key: item.menuItemCode,
                    code: item.menuItemCode,
                    parentCode: item.parentCode,
                };
            }
            else {
                continue;
            }
            children = this.generateMenuItemTree(dataTree, item.menuItemCode);
            node.children = children;
            tree.push(node);
        }
        tree = tree.sort((a, b) => {
            return a.sortKey - b.sortKey;
        });
        return tree;
    }
    /**
     * 遍历树
     */
    forEachMenuTree(tree, callback = () => { }) {
        tree.forEach((item) => {
            callback(item);
            if (item.children && item.children.length) {
                this.forEachMenuTree(item.children, callback);
            }
        });
    }
    generateMenuTreeData(menus = []) {
        return this.generateRootMenuTree(menus);
    }
}
