import { __decorate } from "tslib";
import { Component, Vue, Prop, Watch, } from 'vue-property-decorator';
import { TreeNodeType, OperateAuthMapping } from '@/typings/app-auth';
import { namespace } from 'vuex-class';
import { EmptyPage } from '../common-comp';
import { DomainModel, PageAuth } from './comp';
const AppAuth = namespace('AppAuth');
let Module = class Module extends Vue {
    constructor() {
        super(...arguments);
        this.expandedKeys = [];
        this.selectedKeys = [];
        this.isShowDomainModel = false;
        this.isShowPage = false;
        this.ModelType = TreeNodeType.Model;
        this.PageType = TreeNodeType.Page;
        this.ModelDataType = TreeNodeType.ModelData;
        this.isModelCheckedAll = false;
        this.isPageCheckedAll = false;
    }
    get moduleData() {
        let moduleTree = [];
        if (this.isShowDomainModel) {
            moduleTree = this.moduleTree[0] && this.moduleTree[0].children;
        }
        if (this.isShowPage) {
            moduleTree = this.moduleTree[1] && this.moduleTree[1].children;
        }
        return (moduleTree.find((item) => item.code === this.selectedKeys[0]) || {});
    }
    get isHasGroupContent() {
        return this.moduleTree.some((item) => item.children.length);
    }
    get domainModelCode() {
        let domainModelCode = '';
        if (this.isShowDomainModel) {
            domainModelCode =
                Array.isArray(this.moduleData.bizSchemas) &&
                    this.moduleData.bizSchemas[0] &&
                    this.moduleData.bizSchemas[0].code;
        }
        return domainModelCode;
    }
    onGroupCodeChange() {
        this.init();
    }
    onModuleTreeChange(moduleTree = []) {
        this.onWatchCheckAll(moduleTree);
    }
    init() {
        const path = this.path[this.groupCode];
        if (path) {
            this.isShowPage = false;
            this.isShowDomainModel = false;
            if (path.includes('domainModel')) {
                this.isShowDomainModel = true;
            }
            else if (path.includes('pathAuth')) {
                this.isShowPage = true;
            }
            const pathArr = path.split('-');
            this.expandedKeys.push(pathArr[0]);
            this.selectedKeys = [pathArr[1]];
            return;
        }
        // 默认展开第一个
        if (this.moduleTree.length) {
            const appModule = this.moduleTree[0] || {};
            const moduleKey = appModule.key;
            if (!this.expandedKeys.includes(moduleKey)) {
                this.expandedKeys.push(moduleKey);
            }
            // 默认选中第一个
            if (appModule.children && appModule.children.length) {
                const firstChild = appModule.children[0];
                this.selectedKeys = [firstChild.code];
                const moduleType = moduleKey.substring(moduleKey.indexOf('/'), moduleKey.lastIndexOf('/'));
                this.path[this.groupCode] = `${moduleType}-${firstChild.code}`;
            }
            this.isShowPage = false;
            this.isShowDomainModel = true;
        }
    }
    /**
     * 监听是否选中所有
     */
    onWatchCheckAll(moduleTree = []) {
        const domainModelItem = moduleTree[0];
        const pageModelItem = moduleTree[1];
        const domainModels = domainModelItem.children || [];
        const pages = pageModelItem.children || [];
        domainModelItem.isChckedAll = this.onWatchCheckAllModel(domainModels);
        domainModelItem.indeterminate = this.onWatchIndeterminateDomainModel(domainModels);
        pageModelItem.isChckedAll = this.onWatchCheckAllPages(pages);
        pageModelItem.indeterminate = pageModelItem.isChckedAll
            ? false
            : this.onWatchIndeterminatePages(pages);
    }
    /**
     * 监听是否半选中所有领域模型
     */
    onWatchIndeterminateDomainModel(domainModels = []) {
        if (!domainModels.length)
            return false;
        return domainModels.some((domainModel) => {
            const bizSchemas = domainModel.bizSchemas || [];
            return bizSchemas.some((schemas) => {
                const fieldAuths = schemas.properties || [];
                const serviceData = schemas.serviceData;
                const isCheckAllField = fieldAuths.some((fieldAuth) => {
                    const fieldAuthCheckbox = fieldAuth.fieldAuthCheckbox;
                    const fieldAuthCheckboxKeys = Object.keys(fieldAuthCheckbox);
                    return fieldAuthCheckboxKeys.some((authCheckboxKey) => {
                        const authCheckbox = fieldAuthCheckbox[authCheckboxKey];
                        return authCheckbox.checked;
                    });
                });
                return (serviceData.dataAuth !== 0 ||
                    serviceData.operateAuth.length ||
                    isCheckAllField);
            });
        });
    }
    /**
     * 监听是否选中所有页面权限
     */
    onWatchCheckAllPages(pages = []) {
        if (!pages.length)
            return false;
        return pages.every((page) => {
            const isCheckedPage = page.isCheckedPage;
            const isCheckAllComp = page.components.every((comp) => {
                const componentAuthCheckbox = comp.componentAuthCheckbox;
                const authCheckboxKeys = Object.keys(componentAuthCheckbox);
                return authCheckboxKeys.every((authCheckboxKey) => {
                    const authCheckbox = componentAuthCheckbox[authCheckboxKey];
                    return authCheckbox.checked;
                });
            });
            return isCheckedPage && isCheckAllComp;
        });
    }
    /**
     * 监听是否半选中所有页面权限
     */
    onWatchIndeterminatePages(pages = []) {
        if (!pages.length)
            return false;
        return pages.some((page) => {
            const isCheckedPage = page.isCheckedPage;
            const isCheckAllComp = page.components.some((comp) => {
                const componentAuthCheckbox = comp.componentAuthCheckbox;
                const authCheckboxKeys = Object.keys(componentAuthCheckbox);
                return authCheckboxKeys.some((authCheckboxKey) => {
                    const authCheckbox = componentAuthCheckbox[authCheckboxKey];
                    return authCheckbox.checked;
                });
            });
            return isCheckedPage || isCheckAllComp;
        });
    }
    /**
     * 监听是否选中所有领域模型
     */
    onWatchCheckAllModel(domainModels = []) {
        if (!domainModels.length)
            return false;
        return domainModels.every((domainModel) => {
            const bizSchemas = domainModel.bizSchemas || [];
            return bizSchemas.every((schemas) => {
                const fieldAuths = schemas.properties || [];
                const serviceData = schemas.serviceData;
                const isCheckAllField = fieldAuths.every((fieldAuth) => {
                    const fieldAuthCheckbox = fieldAuth.fieldAuthCheckbox;
                    const fieldAuthCheckboxKeys = Object.keys(fieldAuthCheckbox);
                    return fieldAuthCheckboxKeys.every((authCheckboxKey) => {
                        const authCheckbox = fieldAuthCheckbox[authCheckboxKey];
                        return authCheckbox.checked;
                    });
                });
                return (serviceData.dataAuth !== 0 &&
                    serviceData.operateAuth.length === 3 &&
                    isCheckAllField);
            });
        });
    }
    /**
     * 手动操作选中或取消选中所有领域模型或页面权限
     */
    onChange(e) {
        const moduleItem = e.dataRef;
        const isCheckedAll = moduleItem.isChckedAll;
        if (moduleItem.nodeType === TreeNodeType.Model) {
            this.isModelCheckedAll = isCheckedAll || this.isModelCheckedAll;
            this.isModelCheckedAll = !this.isModelCheckedAll;
            if (this.isModelCheckedAll) {
                this.checkedAllDomainModel(moduleItem.children);
            }
            else {
                this.unCheckedAllDomainModel(moduleItem.children);
            }
        }
        else if (moduleItem.nodeType === TreeNodeType.Page) {
            this.isPageCheckedAll = isCheckedAll || this.isPageCheckedAll;
            this.isPageCheckedAll = !this.isPageCheckedAll;
            if (this.isPageCheckedAll) {
                this.checkedAllPage(moduleItem.children);
            }
            else {
                this.unCheckedAllPage(moduleItem.children);
            }
        }
    }
    /**
     * 手动选中或不选中所有领域模型
     */
    checkDomainModel(domainModel = [], dataAuth = 1, operateAuth = [], isAuthCheckbox = false) {
        for (const model of domainModel) {
            const bizSchemas = model.bizSchemas;
            for (const schemas of bizSchemas) {
                const serviceData = schemas.serviceData;
                serviceData.dataAuth = dataAuth;
                serviceData.operateAuth = operateAuth;
                schemas.properties = schemas.properties.map((item) => {
                    for (const key in item.fieldAuthCheckbox) {
                        if (key === 'isWritable') {
                            const disabledEditWritable = this.disabledWritable(item);
                            if (disabledEditWritable)
                                continue;
                            const authCheckbox = item.fieldAuthCheckbox[key];
                            authCheckbox.checked = isAuthCheckbox;
                        }
                        else {
                            const disabledVisible = this.disabledVisible(item);
                            if (disabledVisible)
                                continue;
                            const authCheckbox = item.fieldAuthCheckbox[key];
                            authCheckbox.checked = isAuthCheckbox;
                        }
                    }
                    return item;
                });
            }
        }
    }
    /**
     * 禁止编辑可写复选框
     * @param record
     * @returns
     */
    disabledWritable(record) {
        const disabledEditTableWritable = !record.editTableWritable;
        let disabledEditTableVsibleOrWrite = false;
        if (!record.isRequiredRule && typeof record.isEditTableVsibleOrWrite === 'boolean') {
            disabledEditTableVsibleOrWrite = !record.isEditTableVsibleOrWrite;
        }
        return disabledEditTableWritable || disabledEditTableVsibleOrWrite;
    }
    /**
     * 禁止编辑可见复选框
     * @param record
     * @returns
     */
    disabledVisible(record) {
        let disabledEditVisible = false;
        if (!record.isRequiredRule && typeof record.isEditTableVsibleOrWrite === 'boolean') {
            disabledEditVisible = !record.isEditTableVsibleOrWrite;
        }
        return disabledEditVisible;
    }
    /**
     * 监听选中或不选中所有页面权限
     */
    checkPage(pages = [], isChecked = false) {
        for (const page of pages) {
            page.isCheckedPage = isChecked;
            const components = page.components || [];
            for (const comp of components) {
                const componentAuthCheckbox = comp.componentAuthCheckbox;
                for (const key in componentAuthCheckbox) {
                    const authCheckbox = componentAuthCheckbox[key];
                    authCheckbox.checked = isChecked;
                }
            }
        }
    }
    /**
     * 监听选中所有领域模型
     */
    checkedAllDomainModel(domainModel = []) {
        const operateAuth = Object.keys(OperateAuthMapping);
        const dataAuth = 4;
        const isAuthCheckbox = true;
        this.checkDomainModel(domainModel, dataAuth, operateAuth, isAuthCheckbox);
    }
    /**
     * 监听取消选中所有领域模型
     */
    unCheckedAllDomainModel(domainModel = []) {
        const operateAuth = [];
        const dataAuth = 0;
        const isAuthCheckbox = false;
        this.checkDomainModel(domainModel, dataAuth, operateAuth, isAuthCheckbox);
    }
    /**
     * 监听选中所有页面
     */
    checkedAllPage(pages = []) {
        this.checkPage(pages, true);
    }
    /**
     * 监听取消选中所有页面
     */
    unCheckedAllPage(pages = []) {
        this.checkPage(pages, false);
    }
    /**
     * 展示树
     */
    onExpand(expandedKeys) {
        this.expandedKeys = expandedKeys;
    }
    /**
     * 单击节点
     */
    async onSelect(selectedKeys, e) {
        const node = e.node.dataRef;
        if (node.nodeType === TreeNodeType.Model ||
            node.nodeType === TreeNodeType.Page) {
            const selectedKey = node.key;
            const index = this.expandedKeys.findIndex((item) => item === selectedKey);
            index >= 0
                ? this.expandedKeys.splice(index, 1)
                : this.expandedKeys.push(selectedKey);
        }
        else {
            if (node.nodeType === TreeNodeType.ModelData) {
                this.isShowDomainModel = true;
                this.isShowPage = false;
            }
            else if (node.nodeType === TreeNodeType.PageData) {
                this.isShowPage = true;
                this.isShowDomainModel = false;
            }
            this.selectedKeys = [node.key];
            this.setPath(node.key, node.nodeType);
        }
    }
    setPath(selectedKey, nodeType) {
        const moduleType = nodeType === TreeNodeType.ModelData ? 'domainModel' : 'pathAuth';
        this.path[this.groupCode] = `${moduleType}-${selectedKey}`;
    }
};
__decorate([
    AppAuth.State('path')
], Module.prototype, "path", void 0);
__decorate([
    Prop({ default: () => [] })
], Module.prototype, "moduleTree", void 0);
__decorate([
    Prop({ default: '' })
], Module.prototype, "groupCode", void 0);
__decorate([
    Watch('groupCode', { immediate: true })
], Module.prototype, "onGroupCodeChange", null);
__decorate([
    Watch('moduleTree', { deep: true, immediate: true })
], Module.prototype, "onModuleTreeChange", null);
Module = __decorate([
    Component({
        name: 'Module',
        components: {
            EmptyPage,
            DomainModel,
            PageAuth,
        },
    })
], Module);
export default Module;
