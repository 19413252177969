/**
 * 所有数据项类型
 */
export var ValueType;
(function (ValueType) {
    // 输入框
    ValueType["Input"] = "SHORT_TEXT";
    // 长文本
    ValueType["Textarea"] = "LONG_TEXT";
    // 数值
    ValueType["Decimal"] = "DECIMAL";
    // 数值
    ValueType["Number"] = "NUMBER";
    // 日期
    ValueType["DatePicker"] = "DATE";
    // 时间
    ValueType["TimePicker"] = "DATE";
    // 日期时间
    ValueType["DateTimePicker"] = "DATE";
    // 逻辑
    ValueType["Boolean"] = "BOOLEAN";
    // 人员单选
    ValueType["StaffPicker"] = "RADIO_PERSON";
    // 人员多选
    ValueType["StaffMultiPicker"] = "MULTI_SELECT_PERSON";
    // 部门单选
    ValueType["DeptPicker"] = "RADIO_DEPARTMENT";
    // 部门多选
    ValueType["DeptMultiPicker"] = "MULTI_SELECT_DEPARTMENT";
    // 人员部门混选
    ValueType["StaffDeptPicker"] = "PERSON_DEPARTMENT_SELECT";
    // 单选文本框
    ValueType["Select"] = "RADIO_TEXT";
    // 多选文本框
    ValueType["MultiSelect"] = "MULTI_SELECT_TEXT";
})(ValueType || (ValueType = {}));
/**
 * 默认值是字符串的valueType
 */
export const StringDefaultValue = [
    ValueType.Input,
    ValueType.Textarea,
    ValueType.Number,
    ValueType.Decimal,
    ValueType.DateTimePicker,
];
/**
 * 默认值是undefined的valueType
 */
export const UndefinedDefaultValue = [ValueType.Select, ValueType.Boolean];
/**
 * 默认值是数组的valueType
 */
export const ArrayDefaultValue = [
    ValueType.StaffPicker,
    ValueType.StaffMultiPicker,
    ValueType.DeptPicker,
    ValueType.DeptMultiPicker,
    ValueType.StaffDeptPicker,
    ValueType.MultiSelect,
];
export var StaffSelectorValue;
(function (StaffSelectorValue) {
    StaffSelectorValue["CurrentLoginUser"] = "1";
    StaffSelectorValue["CurrentLoginDept"] = "2";
    StaffSelectorValue["OrgUser"] = "3";
    StaffSelectorValue["OrgDept"] = "4";
})(StaffSelectorValue || (StaffSelectorValue = {}));
