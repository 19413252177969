import { __decorate } from "tslib";
import { Component, Vue, Emit, Prop, Watch, } from 'vue-property-decorator';
import { TreeNodeType } from '@/typings/app-auth';
let AuthSide = class AuthSide extends Vue {
    constructor() {
        super(...arguments);
        this.expandedKeys = [];
        this.selectedKeys = [];
        this.moduleType = TreeNodeType.GroupAuth;
        this.groupType = TreeNodeType.Group;
    }
    onTreeDataChange(treeData = []) {
        const menuData = treeData[0];
        if (menuData) {
            this.selectedKeys = [menuData.code];
            this.selectNode(menuData);
        }
    }
    // 选中节点事件
    selectNode(node) {
        return node;
    }
    /**
     * 展示树
     */
    onExpand(expandedKeys) {
        this.expandedKeys = expandedKeys;
    }
    /**
     * 单击节点
     */
    onSelect(selectedKeys, e) {
        const node = e.node.dataRef;
        if (node.nodeType === TreeNodeType.Group || node.nodeType === TreeNodeType.MenuAuth) {
            this.selectedKeys = [node.key];
            this.selectNode(node);
        }
        else {
            const selectedKey = node.key;
            const index = this.expandedKeys.findIndex((item) => item === selectedKey);
            index >= 0
                ? this.expandedKeys.splice(index, 1)
                : this.expandedKeys.push(selectedKey);
        }
    }
};
__decorate([
    Prop({ default: () => [] })
], AuthSide.prototype, "treeData", void 0);
__decorate([
    Watch('treeData', { immediate: true })
], AuthSide.prototype, "onTreeDataChange", null);
__decorate([
    Emit('selectNode')
], AuthSide.prototype, "selectNode", null);
AuthSide = __decorate([
    Component({
        name: 'AuthSide',
    })
], AuthSide);
export default AuthSide;
